import styled from 'styled-components';

const getMargin = (props: InfoBoxProps) => {
  if (props.noMargin) {
    return 0;
  }
  if (props.smallMargin) {
    return '0 0 var(--size-sm) 0';
  }
  return 'var(--size-lg) 0';
};

type InfoBoxProps = {
  error?: boolean;
  noMargin?: boolean;
  smallMargin?: boolean;
  preWrap?: boolean;
};

const InfoBox = styled.div<InfoBoxProps>`
  background: ${props => (props.error ? 'var(--red-01-010)' : 'var(--orange-01-lighter)')};
  color: var(--body-text-fg);
  border: var(--border-thin) solid ${props => (props.error ? 'var(--red-01-tinted)' : 'var(--orange-01-light)')};
  padding: var(--size-md);
  margin: ${props => getMargin(props)};
  font-size: ${props => props.theme.font.size.xs};
  line-height: ${props => props.theme.font.lineHeight.md};
  white-space: ${props => props.preWrap && 'pre-wrap'};
`;
InfoBox.displayName = 'InfoBox';

export default InfoBox;
