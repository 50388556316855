import * as profile from './profile';
import * as tos from './tos';
import * as localization from './localization';
import * as apiKeys from './apiKeys';
import * as apiQuotas from './apiQuotas';
import * as session from './session';

export const PROFILE_PATH = '/profile';
export const SERVICE_API_PATH = '/api';

const services = {
  ...apiKeys,
  ...apiQuotas,
  ...localization,
  ...profile,
  ...session,
  ...tos,
};

export default services;
