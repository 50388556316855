import styled from 'styled-components';

export const ReplyContainer = styled.div`
  & > * {
    padding: 0.75em 1.5em 0.75em 1.5em;
    @media (max-width: ${props => props.theme.breakpoints.desktop}px) {
      padding: 0.75em 1em 0.75em 1em;
    }
  }
`;
