import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select-v3';
import Svg from 'components/Svg/Svg';
import { useTranslations } from 'decorators/Translations/translations';
import styled, { useTheme } from 'styled-components';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import charWidth from 'utils/String/charWidth';
import { transparentize } from 'polished';
import { isOptionDisabled } from './utils';

const SelectWrapper = styled.div`
  width: ${props => props.cssWidth};
  color: ${props => (props.lightBackground ? props.theme.colors.black : props.theme.colors.white)};
  pointer-events: auto;
  cursor: pointer;
`;
SelectWrapper.displayName = 'SelectWrapper';

const Arrow = styled(({ lightBackground, ...props }) => <Svg {...props} />)`
  fill: ${props => (props.lightBackground ? props.theme.colors.black : props.theme.colors.white)};
`;
Arrow.displayName = 'Arrow';

const MobileLabel = styled.div`
  position: absolute;
  right: 2em;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.theme.colors.white};
  ${props => props.theme.media.portrait`
    display: none;
  `}
`;
MobileLabel.displayName = 'MobileLabel';

const getCustomStyles = (
  theme,
  { large, lightBackground, center, hideValueOnMobile, menuPositionLeft, showBackground }
) => ({
  option: (provided, { isDisabled, isSelected }) => ({
    ...provided,
    backgroundColor: theme.colors.white,
    color: isDisabled ? theme.colors.darkGray : theme.colors.black,
    fontSize: '0.85em',
    textAlign: 'left',
    fontWeight: isSelected ? theme.fontWeight.bold : theme.fontWeight.normal,
    fontStyle: isDisabled ? 'italic' : 'normal',
    ':hover': {
      backgroundColor: transparentize(0.9, theme.colors.cerulean),
    },
  }),
  control: () => ({
    display: 'flex',
    boxShadow: 'unset',
    fontSize: large ? theme.font.size.md : theme.font.size.xs,
    fontWeight: theme.fontWeight.bold,
    cursor: 'pointer',
    background: showBackground ? (lightBackground ? theme.colors.white : theme.colors.midnight) : 'transparent',
    border: showBackground ? `1px solid ${theme.colors.lightGray}` : 'none',
    borderRadius: showBackground ? '4px' : 'none',
  }),
  input: () => ({
    input: {
      width: '0 !important',
    },
  }),
  singleValue: () => ({
    color: lightBackground ? theme.colors.black : theme.colors.white,
    display: hideValueOnMobile ? 'none' : 'inherit',
    background: 'transparent',
    justifyContent: center ? 'center' : 'flex-end',
    alignItems: 'center',
  }),
  placeholder: () => ({
    color: lightBackground ? theme.colors.black : theme.colors.white,
    display: hideValueOnMobile ? 'none' : 'inherit',
    background: 'transparent',
    justifyContent: center ? 'center' : 'flex-end',
    alignItems: 'center',
  }),
  menu: provided => ({
    ...provided,
    boxShadow: 'none',
    border: `1px solid ${theme.colors.lightGray}`,
    backgroundColor: theme.colors.white,
    borderRadius: '4px',
    zIndex: 10,
    width: 'auto',
    marginTop: 0,
    right: menuPositionLeft ? 0 : provided.right,
    '&:before': !showBackground && {
      position: 'absolute',
      content: '""',
      display: 'block',
      width: 0,
      top: '-10px',
      right: menuPositionLeft ? 0 : '20px',
      borderStyle: 'solid',
      borderWidth: '0 10px 10px',
      borderColor: `${theme.colors.lightGray} transparent`,
    },
    '&:after': !showBackground && {
      position: 'absolute',
      content: '""',
      display: 'block',
      width: 0,
      top: '-9px',
      right: menuPositionLeft ? 0 : '20px',
      borderStyle: 'solid',
      borderColor: `${theme.colors.white} transparent`,
      borderWidth: '0 10px 10px',
    },
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    paddingLeft: showBackground ? 10 : 0,
  }),
  dropdownIndicator: (provided, { children }) => ({
    ...provided,
    ':hover': {},
    color: lightBackground ? theme.colors.black : theme.colors.white,
    padding: children ? 0 : provided.padding,
  }),
});

const Select = ({
  disabled = false,
  value = null,
  placeholder = null,
  options,
  customArrow = null,
  hideValueOnMobile = false,
  lightBackground = false,
  large = false,
  center = false,
  onChange,
  pxWidth,
  autoWidth = false,
  menuPositionLeft = false,
  showBackground = false,
}) => {
  const [t] = useTranslations();
  const theme = useTheme();

  const placeholderText = placeholder || `${t('Select')}...`;

  // Calculate the approximate width of the longest partner name
  let width =
    Math.max(...options.concat([{ label: placeholderText }]).map(c => reduce(c.label?.split('') || [], charWidth, 0))) +
    15;

  // If we are using large label, check that it will fit
  if (large) {
    const valueLabel = value && find(options, { value }).label;
    const labelWidth = (reduce((valueLabel || placeholderText).split(''), charWidth, 0) + 5) * 1.55;
    width = width < labelWidth ? labelWidth : width;
  }
  // Make sure the selector is not overflowing from the window
  width = window.innerWidth - 65 < width ? window.innerWidth - 65 : width;

  const customStyles = React.useMemo(
    () =>
      getCustomStyles(theme, { large, lightBackground, center, hideValueOnMobile, menuPositionLeft, showBackground }),
    [theme, large, lightBackground, center, hideValueOnMobile, menuPositionLeft, showBackground]
  );

  const customComponents = React.useMemo(
    () =>
      customArrow
        ? {
            DropdownIndicator: props => {
              if (React.isValidElement(customArrow)) {
                return <components.DropdownIndicator {...props}>{customArrow}</components.DropdownIndicator>;
              }
              return (
                <components.DropdownIndicator {...props}>
                  <Arrow name={customArrow || 'caret-down'} lightBackground={lightBackground} />
                </components.DropdownIndicator>
              );
            },
            IndicatorSeparator: () => null,
          }
        : {
            IndicatorSeparator: () => null,
          },
    [customArrow, lightBackground]
  );

  const onOptionChange = React.useCallback(selected => onChange(selected.value), [onChange]);

  const cssWidth = autoWidth ? 'auto' : `${pxWidth || width}px`;

  return (
    <SelectWrapper cssWidth={cssWidth} lightBackground={lightBackground}>
      {hideValueOnMobile && <MobileLabel>{t('View as')}</MobileLabel>}
      <ReactSelect
        defaultValue={options.filter(option => option.value === value)}
        value={options.filter(option => option.value === value)}
        placeholder={placeholderText}
        onChange={onOptionChange}
        options={options}
        components={customComponents}
        isDisabled={disabled}
        isOptionDisabled={isOptionDisabled}
        styles={customStyles}
      />
    </SelectWrapper>
  );
};

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  customArrow: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  hideValueOnMobile: PropTypes.bool,
  placeholder: PropTypes.string,
  lightBackground: PropTypes.bool,
  large: PropTypes.bool,
  center: PropTypes.bool,
  pxWidth: PropTypes.number,
  autoWidth: PropTypes.bool,
  menuPositionLeft: PropTypes.bool,
  showBackground: PropTypes.bool,
};

export default Select;
