import styled from 'styled-components';

export const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.font.size.xxs};
  & > *:first-child {
    padding-right: var(--size-xs);
  }
`;
