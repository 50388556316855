import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import isObjectLike from 'lodash/isObjectLike';
import isArray from 'lodash/isArray';
import Select from 'react-select';
import styled, { keyframes, css } from 'styled-components';
import 'react-select/dist/react-select.css';
import SelectedMultiOption from './SelectedMultiOption/SelectedMultiOption';
import { useTranslations } from 'decorators/Translations/translations';

const spinnerSpin = keyframes`
    from    { transform:rotate(0deg);   }
    to      { transform:rotate(360deg); }
`;

const SELECT_ALL_VALUE = '*.*';

export const selectStyles = css`
  &&& {
    min-width: 5em;

    .Select-control {
      display: flex;
      align-items: center;
      min-width: 100%;
      height: var(--input-height);
      background-color: ${props => props.theme.input.background.default};
      padding-right: ${props => props.theme.input.paddingHorizontal};
      border: var(--border-thin) solid var(--input-bc);
      border-bottom-color: ${props => props.highlightError && 'var(--error-color)'};
      border-radius: 0;
      box-shadow: ${props => props.theme.input.shadow.static};
      transition: ${props => props.theme.input.transition};
      line-height: ${props => props.theme.input.lineHeight.default};
      &:hover,
      &:focus {
        box-shadow: ${props => props.theme.input.shadow.active};
        border: ${props => props.theme.input.border.active};
      }
      .Select-value {
        height: auto;
        padding: ${props => (props.multi ? 'var(--size-xxs)' : props.theme.input.padding)};
        margin: ${props => props.multi && '9px 0 0 5px'};
        line-height: ${props => props.theme.input.lineHeight.default};

        .Select--single & {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      .Select-multi-value-wrapper {
        margin-right: auto;
      }
    }

    &.is-open > .Select-control .Select-arrow {
      border-bottom-color: ${props => props.theme.input.font.color.default};
    }
    .Select-menu-outer {
      z-index: 2;
      border-radius: 0;
      box-shadow: ${props => props.theme.input.shadow.active};
      border: ${props => props.theme.input.border.active};
      .Select-menu {
        .Select-option {
          color: ${props => props.theme.input.font.color.default};
        }
        .Select-noresults {
          color: ${props => props.theme.input.font.color.placeholder};
        }
        .Select-option,
        .Select-noresults {
          padding: ${props => props.theme.input.padding};
          font-size: ${props => props.theme.input.font.mobileSize};
          font-family: ${props => props.theme.input.font.family};
          font-weight: ${props => props.theme.font.weight.normal};
          line-height: ${props => props.theme.input.lineHeight.default};
          font-style: ${props => (props.disabled ? 'italic' : '')};
          &:last-child {
            border-radius: none;
          }
          &.is-selected {
            font-weight: ${props => props.theme.font.weight.bold};
            color: ${props => props.theme.input.font.color.default};
          }

          ${props => props.theme.media.landscape`
          font-size: ${props => props.theme.input.font.size};
        `}
        }
      }
    }
    &.is-disabled > .Select-control {
      background-color: ${props => props.theme.input.background.disabled};
      cursor: not-allowed;
    }
    &&& {
      .Select-input,
      .Select-placeholder,
      .Select-value-label,
      .Select-control {
        font-size: ${props => props.theme.input.font.mobileSize};
        line-height: ${props => props.theme.input.lineHeight.default};

        ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.input.font.size};
      `}
      }
      .Select-value-label {
        color: ${props => props.theme.input.font.color.default};
      }
      .Select-placeholder {
        color: ${props => props.theme.input.font.color.placeholder};
        padding: ${props => props.theme.input.padding};
        border: 1px solid transparent;
      }
      .Select-input {
        width: auto;
        height: auto;
        margin-left: calc(${props => props.theme.input.paddingVertical} - 1px);
        padding: 0;
        & > input {
          height: calc(var(--input-height) - 2px);
          box-sizing: border-box !important;
          padding: ${props => props.theme.input.paddingHorizontal} 0;
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
      .Select-arrow-zone {
        padding-right: 0;
        text-align: right;
        height: 1rem;
      }
      .Select-arrow {
        border-top-color: ${props => props.theme.input.font.color.default};
      }
    }
    .Select-loading {
      border: var(--border-bold) solid var(--input-bc-active);
      border-right-color: transparent;
      animation: ${spinnerSpin} 0.5s linear infinite;
    }
    .Select-clear-zone .Select-clear {
      font-size: ${props => props.theme.font.size.sm};
      color: var(--input-subcontrol-fg);
    }
    ${props =>
      props.isFilter &&
      css`
        .Select-control {
          box-shadow: none !important;
          &:focus-within {
            box-shadow: none !important;
            border: var(--border-thin) solid var(--input-bc-active) !important;
          }
        }
        .Select-input,
        .Select-placeholder,
        .Select-value-label,
        .Select-control {
          color: var(--input-fg);
          padding: 0 !important;
        }
        .Select-value {
          padding: var(--size-xxs) var(--size-xl) var(--size-xs) var(--size-xs) !important;
          color: var(--input-option-fg) !important;
          &::before {
            content: '${props.label || ' '}';
            display: block;
            color: var(--input-option-suppelemental-fg);
            font-size: ${props.theme.fontSize.xxxs};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.25;
            padding-bottom: var(--size-xxs);
          }
        }
        .Select-value-label {
          font-size: ${props.theme.fontSize.xs} !important;
        }
        .Select-placeholder {
          padding: var(--size-xxs) var(--size-xl) var(--size-xs) var(--size-xs) !important;
        }
        .Select-input {
          margin-left: 5px !important;
        }
      `}
  }
`;

const StyledSelect = styled(Select)`
  ${selectStyles}
`;
StyledSelect.displayName = 'StyledSelect';

const getLabel = (selectedOption, options) => {
  if (isObjectLike(selectedOption)) {
    return selectedOption.label;
  }
  return options.find(option => option.value === selectedOption)?.label;
};

const getSelectedValue = (value, options) => {
  if (isNil(value)) {
    return '';
  }
  if (isArray(value)) {
    return value;
  }
  const valueToFind = isObjectLike(value) && !isObjectLike(options?.[0]?.value) ? Object.keys(value)[0] : value;
  return find(options, ['value', valueToFind]) || value;
};

// mimic onChange or multi select: return all selected values as option array
const getMultiInputChangeValue = (selectedOption, previouslySelected, options, remove) => {
  if (selectedOption.value === SELECT_ALL_VALUE) {
    return options;
  }
  let valueArray = previouslySelected || []; // fix empty previous value
  if (!Array.isArray(valueArray)) {
    valueArray = [valueArray]; // fix single previous value
  }
  if (previouslySelected.length > 0 && !previouslySelected[0]?.label) {
    valueArray = previouslySelected.map(value => ({ label: getLabel(value, options), value })); // fix simple previous values to option array
  }

  if (remove) {
    return valueArray.filter(value => value.value !== selectedOption.value);
  }
  return valueArray.concat(selectedOption);
};

/**
 * Deprecated React-select v1 ({@link https://react-select.com/}) component.
 *
 * @deprecated Use SelectInputDropdownWithOldStyles instead.
 * @see {@link SelectInputDropdownWithOldStyles}
 */
export const InputSelectDropdown = props => {
  const [t] = useTranslations();
  const ref = useRef();
  const handleMenuOpen = useCallback(() => ref.current.scrollIntoView(), []);

  const value = props.value || get(props.model, props.property);
  const selected = getSelectedValue(value, props.options);
  const selectedValues = (Array.isArray(selected) ? selected : [selected]).map(item => item.value ?? item);
  const options = (props.showAll ? [{ label: t('Select all'), value: SELECT_ALL_VALUE }] : []).concat(
    props.options ? props.options.filter(option => !selectedValues.includes(option.value)) : []
  );
  const showMultiValues = props.multi && selected && Array.isArray(selected); // single value is shown inside select

  const handleChange = selectedOption => {
    let newValue = '';
    if (props.multi) {
      newValue = getMultiInputChangeValue(selectedOption, selected, props.options, false);
    } else if (selectedOption?.value !== undefined) {
      newValue = selectedOption?.value;
    } else if (props.resetValue !== undefined) {
      newValue = props.resetValue;
    }
    props.onChange(props.property, newValue);
  };

  return (
    <>
      <div ref={ref}>
        {showMultiValues &&
          selected.map(value => {
            const option = isObjectLike(value) ? value : { label: getLabel(value, props.options), value: value };
            return (
              <SelectedMultiOption
                key={option.value}
                label={option.label}
                onClick={() =>
                  props.onChange(props.property, getMultiInputChangeValue(option, selected, props.options, true))
                }
              />
            );
          })}
      </div>
      <StyledSelect
        className={props.className}
        defaultMenuIsOpen={true}
        name={props.id}
        value={showMultiValues ? '' : selected}
        required={props.required && (!props.multi || !selected)} // trigger required to multi select only if there is no value
        disabled={props.disabled}
        clearable={props.clearable}
        filterOption={props.filterOption}
        onInputChange={value => (props.onInput ? props.onInput(value) : undefined)}
        onChange={handleChange}
        options={options}
        placeholder={props.placeholder || `${t('Select')}...`}
        noResultsText={props.noResultsText || t('No results found')}
        clearValueText={t('Clear value')}
        isLoading={props.loading}
        onBlurResetsInput={false}
        menuPortalTarget={props.menuPortalTarget}
        onOpen={props.scrollToMenu ? handleMenuOpen : undefined}
        menuPlacement={props.menuPlacement}
        arrowRenderer={props.arrowRenderer}
        label={props.label}
        isFilter={props.isFilter}
        highlightError={props.highlightError}
        optionRenderer={props.optionRenderer}
      />
    </>
  );
};
InputSelectDropdown.displayName = 'InputSelectDropdown';

InputSelectDropdown.propTypes = {
  className: PropTypes.string,
  arrowRenderer: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.number]),
  model: PropTypes.object,
  options: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  filterOption: PropTypes.func,
  isFilter: PropTypes.bool,
  label: PropTypes.string,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  property: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  multi: PropTypes.bool,
  showAll: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noResultsText: PropTypes.string,
  loading: PropTypes.bool,
  menuPortalTarget: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.elementType })]),
  scrollToMenu: PropTypes.bool,
  menuPlacement: PropTypes.string,
  highlightError: PropTypes.bool,
  resetValue: PropTypes.any,
  optionRenderer: PropTypes.func,
};

export default InputSelectDropdown;
