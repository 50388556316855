import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import styled from 'styled-components';
import { selectStyles } from 'components/Form/InputSelectDropdown';

const StyledSelect = styled(Select.Creatable)`
  ${selectStyles}
`;

const StyledAsyncSelect = styled(Select.AsyncCreatable)`
  ${selectStyles}
`;

const SelectOrType = ({ t, options, onChange, onSearch, loading, highlightError, value }) => {
  const promptTextCreator = label => t('Select "{0}"', label);
  const isOptionUnique = ({ option, options, valueKey }) => {
    const lowerCaseOptionValue = String(option[valueKey]).toLowerCase();
    return !options.some(otherOption => String(otherOption[valueKey]).toLowerCase() === lowerCaseOptionValue);
  };
  if (typeof onSearch === 'function') {
    return (
      <StyledAsyncSelect
        autoload
        loadOptions={onSearch}
        loadingPlaceholder={t('Loading...')}
        onChange={onChange}
        placeholder={t('Select or type')}
        noResultsText={t('Type and press enter')}
        promptTextCreator={promptTextCreator}
        isOptionUnique={isOptionUnique}
        isLoading={loading}
        inputProps={{ autocomplete: 'none' }}
        highlightError={highlightError}
      />
    );
  }

  return (
    <StyledSelect
      options={options}
      onChange={onChange}
      placeholder={t('Select or type')}
      noResultsText={t('Type and press enter')}
      promptTextCreator={promptTextCreator}
      isOptionUnique={isOptionUnique}
      isLoading={loading}
      inputProps={{ autocomplete: 'none' }}
      highlightError={highlightError}
      value={value}
    />
  );
};

SelectOrType.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
  highlightError: PropTypes.bool,
  value: PropTypes.object, // Only provide the value if you want it to be shown in the select text input
};

export default SelectOrType;
