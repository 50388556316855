import PropTypes from 'prop-types';

export const additionalSalePropType = PropTypes.shape({
  status: PropTypes.string,
  action: PropTypes.string,
  technicalCategory: PropTypes.string,
  observationCategory: PropTypes.string,
  priority: PropTypes.string,
  value: PropTypes.string,
  description: PropTypes.string,
  customerName: PropTypes.string,
  customerAddress: PropTypes.string,
  customerPostalCode: PropTypes.string,
  customerCity: PropTypes.string,
  customerCountry: PropTypes.string,
  division: PropTypes.string,
  purchaseOrderNumber: PropTypes.string,
  created: PropTypes.string,
  modified: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    })
  ),
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      creator: PropTypes.string,
      comment: PropTypes.string,
      action: PropTypes.string,
      created: PropTypes.string,
      attachments: PropTypes.arrayOf(PropTypes.shape({ fileId: PropTypes.number, name: PropTypes.string })),
    })
  ),
  token: PropTypes.shape({
    token: PropTypes.string,
    type: PropTypes.string,
  }),
});

/**
 * AdditionalSaleAction enum values:
 */

export const AdditionalSaleStatus = {
  OPEN: 'open',
  CLOSED: 'closed',
  PENDING: 'pending',
};

const NOW = 'now';
const REQUEST_BUDGET_PRICE = 'request-budget-price';
const REQUEST_INFO = 'request-info';
const DECLINE = 'decline';
export const WRONG_PERSON = 'wrong-person';

export const BIG_VALUE_ANSWER_OPTIONS = [NOW, REQUEST_BUDGET_PRICE, REQUEST_INFO, WRONG_PERSON, DECLINE];

export const SMALL_VALUE_ANSWER_OPTIONS = [NOW, REQUEST_INFO, WRONG_PERSON, DECLINE];

export const getAnswerOptionsByValue = value => {
  if (value === 'big') {
    return BIG_VALUE_ANSWER_OPTIONS;
  }
  return SMALL_VALUE_ANSWER_OPTIONS;
};

export const isActionForClosing = action => ![REQUEST_BUDGET_PRICE, REQUEST_INFO, WRONG_PERSON].includes(action);

export const isActionWrongPerson = action => action === WRONG_PERSON;

export const isActionNow = action => action === NOW;

/**
 * AdditionalSale wrong person actions:
 */
const WRONG_PERSON_ACTION_UNKNOWN = 'wrong-person-unknown';
const WRONG_PERSON_ACTION_EMAIL = 'wrong-person-email';

export const getWrongPersonAnswerOptions = () => [WRONG_PERSON_ACTION_UNKNOWN, WRONG_PERSON_ACTION_EMAIL];

export const isCorrectPersonEmailKnown = action => action === WRONG_PERSON_ACTION_EMAIL;

/**
 * AdditionalSaleTokenType enum values:
 */

const TOKEN_CUSTOMER = 'customer';
const TOKEN_MANAGER = 'manager';

export const isCustomerTokenType = type => type === TOKEN_CUSTOMER;
export const isManagerTokenType = type => type === TOKEN_MANAGER;

/**
 * Legal requirement: user should see information about general terms and conditions when
 * ordering work from Caverion.
 *
 * Since this text is bound to Caverion Suomi, we don't want to use official translation tool.
 */

export const SUOMI_FI_TERM_AND_CONDITIONS_TEXT =
  'Mikäli tilaajan ja Caverion Suomi Oy:n välillä ei ole voimassa olevaa tilausta koskevaa sopimusta tai jos ei ole muulla tavoin kirjallisesti toisin sovittu, sovelletaan tilaukseen Caverion Suomi Oy:n palveluiden toimittamisen yleisiä sopimusehtoja.';

export const SUOMI_EN_TERM_AND_CONDITIONS_TEXT =
  "Unless there is a valid agreement between the client and Caverion Suomi Oy regarding the order, or unless otherwise agreed in writing, Caverion Suomi Oy's general terms and conditions for services shall apply to the order.";

export const openGeneralTermsAndConditions = selectedLanguage => {
  let newWindow;
  if (selectedLanguage === 'fi') {
    newWindow = window.open(
      '/caverion_palveluiden_toimittamisen_yleiset_sopimusehdot.pdf',
      '_blank',
      'noopener,noreferrer'
    );
  } else {
    newWindow = window.open('/caverion_general_terms_and_conditions.pdf', '_blank', 'noopener,noreferrer');
  }
  if (newWindow) newWindow.opener = null;
};
