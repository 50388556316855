import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 0.75em;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  flex-wrap: wrap;

  & > * {
    flex: 1 0 45%;
  }
`;

export const Title = styled.h3`
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const Info = styled.div`
  color: ${props => props.theme.colors.black};
  font-size: 12px;
  line-height: ${props => props.theme.font.lineHeight.md};

  ${props => props.theme.media.landscape`
  `}
`;

export const TitleRow = styled.div`
  & > *:not(:first-child) {
    margin-top: 0.25em;
  }
`;

export const ButtonContainer = styled.div`
  flex-basis: 126px;
  button {
    width: 100%;
  }
  ${props => props.theme.media.landscape`
    margin-top: 0;
  `};
`;
