import values, {
  sensorValuesWithPublicView,
  firstValueLimit,
  sensorsLatestValues,
  sensorValuesFind,
  importGenericSensorValues,
  importWasteSensorValues,
} from './values';
import energyChartValues from './energyChartValues';
import * as annotations from './annotations';
import * as alarms from './alarms';
import sensorAlarms from './sensorAlarms';
import * as chartValues from './chartValues';
import kpiValues from './kpiValues';

export const IOT_PATH = '/iot';
export const SERVICE_API_PATH = '/api';

const exports = {
  ...alarms,
  ...annotations,
  ...chartValues,
  ...energyChartValues,
  ...kpiValues,
  ...sensorAlarms,
  ...values,
  sensorsLatestValues,
  sensorValuesWithPublicView,
  firstValueLimit,
  sensorValuesFind,
  importGenericSensorValues,
  importWasteSensorValues,
};

export default exports;
