import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { StandardPage } from 'components/index';
import themes from 'styles/themes';

const Anonymous = ({ children, title, noFooter, noHeader, customTheme }) => (
  <ThemeProvider theme={customTheme || themes.customerPlatform}>
    <StandardPage noMargin isAnonymous noFooter={noFooter} noHeader={noHeader}>
      <Helmet titleTemplate={title} />
      {children}
    </StandardPage>
  </ThemeProvider>
);

Anonymous.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool,
  customTheme: PropTypes.object,
};

export default Anonymous;
