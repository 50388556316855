import React, { ReactNode } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import { css } from '@emotion/css';
import SelectV5, { components, mergeStyles, StylesConfig, Props, GroupBase, NoticeProps } from 'react-select-v5';
import {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  LoadingIndicatorProps,
} from 'react-select-v5/dist/declarations/src/components/indicators';

import Svg from 'components/Svg/Svg';
import Loader from 'components/Loader/Loader';

declare module 'react-select-v5/dist/declarations/src/Select' {
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    label?: ReactNode;
  }
}

export function defaultSelectInputStyles<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  theme: DefaultTheme
): StylesConfig<Option, IsMulti, Group> {
  return {
    control: (provided, { isDisabled }) => ({
      ...provided,
      display: 'flex',
      boxShadow: 'unset',
      fontSize: theme.font.size.xs,
      fontWeight: theme.font.weight.normal,
      cursor: isDisabled ? 'auto' : 'pointer',
      backgroundColor: 'var(--input-bg)',
      border: '1px solid var(--input-bc)',
      borderRadius: '1px',
      padding: 'var(--size-xs) var(--size-sm)',
    }),
    valueContainer: provided => ({
      ...provided,
      padding: `0 ${theme.spacing.xs} 0 0`,
    }),
    input: provided => ({
      ...provided,
      fontSize: theme.font.size.xs,
      fontWeight: theme.font.weight.normal,
      input: {
        boxShadow: 'unset',
        height: `${theme.font.lineHeight.md}em`,
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: 'var(--input-option-fg)',
      fontWeight: theme.font.weight.normal,
      backgroundColor: 'transparent',
      margin: 0,
    }),
    placeholder: provided => ({
      ...provided,
      color: 'var(--input-placeholder-fg)',
      fontWeight: theme.font.weight.normal,
      backgroundColor: 'transparent',
      margin: 0,
    }),
    indicatorsContainer: () => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      fontSize: '0.5rem',
    }),
    dropdownIndicator: (provided, { isDisabled, isFocused, selectProps }) => ({
      ...provided,
      fill: isDisabled
        ? 'var(--input-subcontrol-fg-disabled)'
        : isFocused
        ? 'var(--input-subcontrol-fg-focused)'
        : 'var(--input-subcontrol-fg)',
      padding: 0,
      transition: 'all .2s ease',
      transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
    }),
    clearIndicator: (provided, { isFocused }) => ({
      ...provided,
      fill: isFocused ? 'var(--input-subcontrol-fg-focused)' : 'var(--input-subcontrol-fg)',
      padding: theme.spacing.xs,
    }),
    loadingIndicator: provided => ({
      ...provided,
      padding: 0,
      marginRight: theme.spacing.xxs,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: provided => ({
      ...provided,
      boxShadow: 'none',
      border: `1px solid var(--input-bc)`,
      backgroundColor: 'var(--input-bg)',
      borderRadius: '1px',
      zIndex: 10,
      marginTop: '-1px',
      marginBottom: '-1px',
    }),
    option: (provided, { isFocused, isDisabled, isSelected }) => ({
      ...provided,
      color: isDisabled ? 'var(--input-option-fg-disabled)' : 'var(--input-option-fg)',
      fontSize: theme.font.size.xs,
      fontWeight: isSelected ? theme.fontWeight.bold : theme.fontWeight.normal,
      fontStyle: 'normal',
      backgroundColor: 'var(--input-option-bg)',
      ...(isSelected && { backgroundColor: 'var(--input-option-bg-selected)' }),
      ...(isFocused && { backgroundColor: 'var(--input-option-bg-hover)' }),
      ...(isDisabled && { backgroundColor: 'var(--input-bg-disabled)' }),
      ':hover': {
        ...(!isDisabled && { backgroundColor: 'var(--input-option-bg-hover)' }),
      },
      textAlign: 'left',
      cursor: isDisabled ? 'default' : 'pointer',
      wordBreak: 'normal',
      overflowWrap: 'anywhere',
    }),
  };
}

export function optionHasTruthyDisabledField<Option>(option: Option): boolean {
  return typeof option === 'object' && !!option && 'disabled' in option && !!option.disabled;
}

export function SelectInputV5DownChevron<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
): JSX.Element {
  return (
    <components.DropdownIndicator {...props}>
      <Svg name="caret-down" />
    </components.DropdownIndicator>
  );
}

export function SelectInputV5ClearIndicator<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: ClearIndicatorProps<Option, IsMulti, Group>
): JSX.Element {
  return <components.ClearIndicator {...props}>{<Svg name="remove" />}</components.ClearIndicator>;
}

export function SelectInputV5LoadingIndicator<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: LoadingIndicatorProps<Option, IsMulti, Group>
): JSX.Element {
  const loadingIndicatorStyles = props.getStyles('loadingIndicator', props);
  return (
    <div className={css(loadingIndicatorStyles)}>
      <Loader color="BLACK" size="SMALL" />
    </div>
  );
}

export function SelectInputV5LoadingMessage<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: NoticeProps<Option, IsMulti, Group>
): JSX.Element {
  return (
    <components.LoadingMessage {...props}>
      <Loader color="GRAY" size="MEDIUM" />
    </components.LoadingMessage>
  );
}

/**
 * React-select v5 ({@link https://react-select.com/}) component with defaults:
 * - UI v3 styles
 * - Default `isOptionDisabled` implementation (option field `disabled` is true)
 * - Custom `DownChevron` component (override in `components` prop if needed)
 * - Custom `LoadingMessage` component (override in `components` prop if needed)
 * - Custom `ClearIndicator` component (override in `components` prop if needed)
 *
 * This is purposefully a very generic component. Please don't extend it without careful
 * consideration. Use this component instead as the heart of more specialized components.
 * This way excess interface bloat and magic can be avoided.
 *
 * @implements UIv3
 * @see {@link PaginationPageSizeSelector}
 */
export function SelectInputV5<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  styles,
  isOptionDisabled = optionHasTruthyDisabledField,
  components,
  ...restProps
}: Props<Option, IsMulti, Group>): JSX.Element {
  const theme = useTheme();

  const finalStyles = mergeStyles(defaultSelectInputStyles<Option, IsMulti, Group>(theme), styles);

  const finalComponents: Props<Option, IsMulti, Group>['components'] = {
    DropdownIndicator: SelectInputV5DownChevron,
    ClearIndicator: SelectInputV5ClearIndicator,
    LoadingIndicator: SelectInputV5LoadingIndicator,
    LoadingMessage: SelectInputV5LoadingMessage,
    ...components,
  };

  return (
    <SelectV5 styles={finalStyles} isOptionDisabled={isOptionDisabled} components={finalComponents} {...restProps} />
  );
}
