import React from 'react';
import { ActionMeta, OnChangeValue } from 'react-select-v5';

import { SelectFilter, SelectFilterOptionType, SelectFilterProps } from 'components/Filters/SelectFilter/SelectFilter';

export type FilterOption = {
  label: string;
  value: string;
};

type ObservationsSelectFilterProps<OptionType, IsMulti extends boolean> = SelectFilterProps<OptionType, IsMulti> & {
  property: string;
  model: { [key: string]: string[] };
  onChange: (property: string, selectedOptions: string[] | undefined) => void;
  options: FilterOption[];
};

export function ObservationsSelectFilter<
  IsMulti extends boolean,
  ValueType extends IsMulti extends true ? FilterOption[] : FilterOption
>({
  property,
  model,
  onChange,
  isMulti,
  options,
  ...restProps
}: ObservationsSelectFilterProps<FilterOption, IsMulti>): JSX.Element {
  const valueOptions = options?.filter(option => !!model[property]?.includes(option.value)) ?? [];
  const value = (isMulti ? valueOptions : valueOptions[0]) as ValueType;
  const isAllSelected = model[property]?.length === options?.length;

  return (
    <SelectFilter
      {...restProps}
      options={options}
      value={value}
      isMulti={isMulti}
      onChange={(option: OnChangeValue<FilterOption, IsMulti>, actionMeta: ActionMeta<SelectFilterOptionType>) => {
        let selectedOptions: string[] | undefined;

        if (actionMeta.action === 'select-option' || actionMeta.action === 'deselect-option') {
          selectedOptions = Array.isArray(option)
            ? option?.map(option => option.value)
            : [(option as FilterOption)?.value];
        }
        if (actionMeta.action === 'clear') {
          selectedOptions = undefined;
        }
        if (actionMeta?.option?.value === 'toggle-select-all') {
          selectedOptions = isAllSelected ? undefined : options?.map(option => option.value);
        }
        onChange?.(property, selectedOptions);
      }}
      isAllSelected={isAllSelected}
    />
  );
}
