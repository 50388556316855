import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router';
import translations from 'decorators/Translations/translations';
import includes from 'lodash/includes';
import throttle from 'lodash/throttle';
import capitalize from 'lodash/capitalize';
import { subMonths } from 'date-fns';
import { format } from 'utils/Date/dateFormatter';

import { connector } from './connectKPIModule';
import { isValidPartner } from 'utils/Data/partners';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { loadKPIContainer } from 'redux/modules';

import ConsumptionKPI from './ConsumptionKPI';
import StatusInfos from 'components/StatusInfo/StatusInfos';
import StatusInfo from 'components/StatusInfo/StatusInfo';
import { withErrorBoundary } from 'components/ErrorPage/ErrorBoundary';
import { ViewMode } from 'constants/maintenance';

import { formatValue } from 'utils/Data/values';

const formatWaterValue = value => ({ value: typeof value === 'number' ? value.toFixed(1) : value, unit: 'm³' });

const KPIModule = ({
  dispatch,
  t,
  theme,
  profileKpis,
  match: {
    params: { partnerNumber },
  },
  history,
  profile,
  sideNaviExtended,
  kpis,
}) => {
  const getCardCount = () => {
    const width = window.innerWidth;
    if (width >= theme.breakpoints.bigDesktop) {
      return 6;
    }
    if (width >= 1400 || (width >= theme.breakpoints.desktop && !sideNaviExtended)) {
      return 5;
    }
    if (width >= 1200 || (width >= theme.breakpoints.landscape && !sideNaviExtended)) {
      return 4;
    }

    return sideNaviExtended ? 3 : 4;
  };
  const [cardCount, setCardCount] = React.useState(getCardCount());
  const throttledSetCardCount = throttle(() => setCardCount(getCardCount()), 100);

  React.useEffect(() => {
    window.addEventListener('resize', throttledSetCardCount);

    return () => {
      window.removeEventListener('resize', throttledSetCardCount);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (partnerNumber) {
      dispatch(
        loadKPIContainer(partnerNumber, profile.serviceOrderStartDate, profileKpis, profile.buildingPerformanceSources)
      );
    }
  }, [partnerNumber, profile.buildingPerformanceSources]); // eslint-disable-line react-hooks/exhaustive-deps

  const partnerPart = !isValidPartner(partnerNumber) ? '' : `/${partnerNumber}`;
  const year = new Date().getFullYear();
  const serviceOrdersLink = `${partnerPart}/Maintenance/ServiceOrders?year=${year}`;
  const plannedMaintenanceLink = `${partnerPart}/Maintenance/PlannedMaintenance?year=${year}&mode=${ViewMode.CALENDAR_PLAN}`;
  const alarmsLink = `${partnerPart}/RemoteCenter/Alarms`;

  const commonProps = {
    t,
    sideNaviExtended,
    valuePosition: 'outside',
  };

  const consumptionProps = {
    ...commonProps,
    history,
    partnerNumber,
    isConsumption: true,
  };

  const cards = [];
  includes(profileKpis, 'serviceOrders') &&
    cards.push({
      ...commonProps,
      loading: kpis.serviceOrders.loading,
      heading: t('Service Orders'),
      value: kpis.serviceOrders.value,
      unit: '%',
      spaceBeforeUnit: false,
      color: kpis.serviceOrders.value >= 90 ? theme.colors.emerald : undefined,
      valueNote: `${t('Completed')} (${year})`,
      onClick: () => history.push(serviceOrdersLink),
      ctxHelp: `${CTXHELP_PREFIX} KPI Service Orders`,
    });
  includes(profileKpis, 'plannedMaintenance') &&
    cards.push({
      ...commonProps,
      loading: kpis.plannedMaintenance.loading,
      heading: t('Planned Maintenance'),
      value: kpis.plannedMaintenance.value,
      unit: '%',
      spaceBeforeUnit: false,
      valueNote: `${t('Completed')} (${year})`,
      onClick: () => history.push(plannedMaintenanceLink),
      ctxHelp: `${CTXHELP_PREFIX} KPI Planned Maintenance`,
      color: theme.status.neutralColor,
    });
  includes(profileKpis, 'alarms') &&
    cards.push({
      ...commonProps,
      loading: kpis.alarms.loading,
      heading: t('Alarms'),
      value: kpis.alarms.value,
      unit: '%',
      spaceBeforeUnit: false,
      valueNote: `${t('Handled')} (${t('Last 365 Days')})`,
      ctxHelp: `${CTXHELP_PREFIX} KPI Alarms`,
      onClick: () => history.push(alarmsLink),
    });
  includes(profileKpis, 'alarmHandlingTime') &&
    cards.push({
      ...commonProps,
      loading: kpis.alarmHandlingTime.loading,
      heading: t('Alarm Handling Time'),
      value: kpis.alarmHandlingTime.mean,
      positiveValue: kpis.alarmHandlingTime.positiveValue,
      unit: t('min'),
      valueNote: `${t('Average')} (${t('Last 365 days')})`,
      ctxHelp: `${CTXHELP_PREFIX} KPI Alarm Handling Time`,
      onClick: () => history.push(alarmsLink),
      isPerformance: true,
    });
  includes(profileKpis, 'alarmTimeToAction') &&
    cards.push({
      ...commonProps,
      loading: kpis.alarmTimeToAction.loading,
      heading: t('Alarm Time to Action'),
      value: kpis.alarmTimeToAction.mean,
      positiveValue: kpis.alarmTimeToAction.positiveValue,
      unit: t('min'),
      valueNote: `${t('Average')} (${t('Last 365 days')})`,
      ctxHelp: `${CTXHELP_PREFIX} KPI Alarm Time to Action`,
      onClick: () => history.push(alarmsLink),
      isPerformance: true,
    });
  includes(profileKpis, 'inspections') &&
    cards.push({
      ...commonProps,
      loading: kpis.inspection.loading,
      heading: t('Inspections'),
      value: kpis.inspection.value,
      valueNote: `${t('Completed / SLA')} (${year})`,
      bars: kpis.inspection.slas,
      ctxHelp: `${CTXHELP_PREFIX} KPI Inspections`,
      onClick: () => history.push(`${partnerPart}/RemoteCenter/Inspections`),
    });
  includes(profileKpis, 'observations') &&
    cards.push({
      ...commonProps,
      loading: kpis.observation.loading,
      heading: t('Observations'),
      value: kpis.observation.value,
      unit: '%',
      spaceBeforeUnit: false,
      valueNote: `${t('Completed')} (${t('Last 365 Days')})`,
      ctxHelp: `${CTXHELP_PREFIX} KPI Observations`,
      onClick: () => history.push(`${partnerPart}/RemoteCenter/Observations`),
      color: theme.status.neutralColor,
    });
  includes(profileKpis, 'energy') &&
    cards.push({
      ...commonProps,
      'data-test-id': 'KpiPartnerEnergy',
      loading: kpis.energyRating.loading,
      precision: 1,
      heading: t('Energy Rating'),
      value: kpis.energyRating.latest,
      valueNote: capitalize(format(subMonths(new Date(), 1), 'LLLL')),
      unit: 'kWh/m²',
      positiveValue: kpis.energyRating.positiveValue,
      positiveValueDirection: 'down',
      onClick: () => history.push(`/${partnerNumber}/Energy`),
      ctxHelp: `${CTXHELP_PREFIX} KPI Energy Rating`,
    });

  includes(profileKpis, 'energyConsumption') &&
    cards.push({
      ...consumptionProps,
      kpi: kpis.energyConsumption,
      heading: 'Energy consumption',
      formatValue,
      ctxHelp: `${CTXHELP_PREFIX} KPI Energy Consumption`,
    });

  includes(profileKpis, 'electricity') &&
    cards.push({
      ...consumptionProps,
      kpi: kpis.electricity,
      heading: 'Electricity',
      formatValue,
      ctxHelp: `${CTXHELP_PREFIX} KPI Electricity`,
    });

  (includes(profileKpis, 'heating') || includes(profileKpis, 'districtHeating')) &&
    cards.push({
      ...consumptionProps,
      kpi: kpis.heating,
      heading: 'Heating',
      formatValue,
      ctxHelp: `${CTXHELP_PREFIX} KPI Heating`,
    });

  includes(profileKpis, 'cooling') &&
    cards.push({
      ...consumptionProps,
      kpi: kpis.cooling,
      heading: 'Cooling',
      formatValue,
      ctxHelp: `${CTXHELP_PREFIX} KPI Cooling`,
    });

  includes(profileKpis, 'water') &&
    cards.push({
      ...consumptionProps,
      kpi: kpis.water,
      heading: 'Water',
      formatValue: formatWaterValue,
      ctxHelp: `${CTXHELP_PREFIX} KPI Water`,
    });
  includes(profileKpis, 'indoorAirQuality') &&
    cards.push({
      ...commonProps,
      loading: kpis.airQuality.loading,
      heading: t('Indoor Air Quality'),
      value: kpis.airQuality.value,
      unit: '%',
      spaceBeforeUnit: false,
      valueNote: t('7 Days Average'),
      ctxHelp: `${CTXHELP_PREFIX} KPI Indoor Air Quality`,
      isAirQuality: true,
    });
  if (cards.length > 0) {
    return (
      <StatusInfos>
        {cards.map((card, index) => {
          const ctxHelpPosition = cardCount && (index + 1) % cardCount === 0 && 'left';
          const props = {
            key: card.heading,
            ctxHelpPosition: ctxHelpPosition || undefined,
          };

          if (card.isConsumption) {
            return <ConsumptionKPI {...card} {...props} />;
          }
          return <StatusInfo {...card} {...props} />;
        })}
      </StatusInfos>
    );
  }
  return null;
};

KPIModule.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  profileKpis: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  kpis: PropTypes.objectOf(
    PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      chart: PropTypes.shape({
        type: PropTypes.oneOf(['line', 'column']),
        data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      }),
      // depending of the kpi:
      mean: PropTypes.number,
      positiveValue: PropTypes.bool,
      latest: PropTypes.number,
      slas: PropTypes.array,
    })
  ).isRequired,
  sideNaviExtended: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withErrorBoundary(withRouter(withTheme(translations(connector(KPIModule)))));
