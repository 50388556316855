import { useTranslations } from 'decorators/Translations/translations';
import React from 'react';
import * as Styled from './ServiceOrderView.styled';

const ServiceOrderSnackbar = ({ replyInViewport, checklistAvailable, toggleShowChecklist, scrollReplyToView, t }) => {
  return (
    <Styled.SnackBar variant="confirmation" visible={!replyInViewport}>
      {checklistAvailable && (
        <Styled.PrimaryButton add large onClick={toggleShowChecklist}>
          {t('Open checklist')}
        </Styled.PrimaryButton>
      )}
      <Styled.PrimaryButton add large onClick={scrollReplyToView}>
        {t('Reply')}
      </Styled.PrimaryButton>
    </Styled.SnackBar>
  );
};

export default ServiceOrderSnackbar;
