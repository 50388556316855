import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { ConfigCategory, ConfigKey } from 'types/Division';

import MasterDataService from 'services/masterData';
import { useParams } from 'react-router-dom';
import translations from 'decorators/Translations/translations';
import SelectOrType from 'components/SelectOrType/SelectOrType';
import SelectedMultiOption from 'components/Form/SelectedMultiOption/SelectedMultiOption';

import { getPartnerDivision } from 'utils/Data/partners';
import { SERVICE_CLASSIFICATION_CODE_TYPE } from 'constants/maintenance';

const Relative = styled.div`
  position: relative;
`;

const RequiredInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
`;

const InfoText = styled.div`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
  line-height: ${props => props.theme.font.lineHeight.lg};
  margin: calc(-1 * var(--size-xs)) 0 var(--size-xs);
`;

export const getClassificationCodeLabel = classification => {
  let label = classification.code;
  if (classification.description) {
    label = `${label} - ${classification.description}`;
  }
  return label;
};

const CategoryInput = ({ model, property, onChange, categoryOptions = [], loading, t, highlightError }) => {
  const { partnerNumber } = useParams();
  const customer = useSelector(state => state.customer.customers[partnerNumber]);
  const profile = useSelector(state => state.profile.profile);
  const division = getPartnerDivision(customer) || profile.division;
  const [useClassificationCodes, setUseClassificationCodes] = useState();

  useEffect(() => {
    MasterDataService.getDivisionConfig({
      query: {
        category: ConfigCategory.Classifications,
        name: ConfigKey.EnableClassificationsInLongTermPlanning,
        division: division,
      },
    }).then(config => {
      const useClassificationCodesConfig = config?.find(
        configItem =>
          configItem.division === division && configItem.name === ConfigKey.EnableClassificationsInLongTermPlanning
      );
      setUseClassificationCodes(useClassificationCodesConfig?.value === 'true');
    });
  }, [division]);

  const searchClassificationCodes = useMemo(
    () =>
      debounce(async (searchText, callback) => {
        const results = await MasterDataService.classificationCodes({
          division,
          type: SERVICE_CLASSIFICATION_CODE_TYPE,
          searchText: searchText || undefined,
        });
        const codes = results.map(classification => ({
          value: classification.code,
          label: getClassificationCodeLabel(classification),
        }));
        const options = [
          ...codes,
          ...categoryOptions.filter(option => !codes.some(code => code.value === option.value)),
        ];
        options.sort((a, b) => a.value?.localeCompare(b.value));
        callback(null, { options, complete: true });
      }, 300),
    [division, categoryOptions]
  );

  const selectedCategories = model[property] || [];

  const addCategory = categoryName => {
    if (!selectedCategories.some(category => category.category === categoryName)) {
      onChange(property, [...selectedCategories, { category: categoryName }]);
    }
  };

  const handleChange = selectedOption => {
    if (selectedOption.value) {
      addCategory(selectedOption.value);
    }
  };

  const handleTagClick = tagCategory => {
    const filteredCategories = selectedCategories.filter(category => {
      if (tagCategory.id) {
        return tagCategory.id !== category.id;
      }
      if (category.id) {
        return true;
      }
      return tagCategory.category !== category.category;
    });
    onChange(property, filteredCategories);
  };

  return (
    <Relative>
      <InfoText>{t('Select categories from the list or write and press enter.')}</InfoText>
      {selectedCategories.map(category => (
        <SelectedMultiOption
          key={category.id || category.category}
          label={category.category}
          onClick={() => handleTagClick(category)}
        />
      ))}
      {selectedCategories.length === 0 && <RequiredInput required />}
      <SelectOrType
        t={t}
        onChange={handleChange}
        options={useClassificationCodes === false ? categoryOptions : undefined}
        onSearch={useClassificationCodes === true ? searchClassificationCodes : undefined}
        loading={loading}
        highlightError={highlightError}
      />
    </Relative>
  );
};

CategoryInput.propTypes = {
  model: PropTypes.object.isRequired,
  property: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  categoryOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  highlightError: PropTypes.bool,
};

export default translations(CategoryInput);
