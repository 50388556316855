import styled from 'styled-components';
import { flexRender, Row, Table } from '@tanstack/react-table';
import { Header, Cell, RowData, HeaderGroup } from '@tanstack/table-core/build/lib/types';
import { SortDirection } from '@tanstack/table-core/build/lib/features/Sorting';

import React, {
  Ref,
  ComponentType,
  PropsWithChildren,
  CSSProperties,
  HTMLAttributes,
  TableHTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
  useRef,
  forwardRef,
} from 'react';

import { LeftShadow, RightShadow } from 'components/HorizontalScroll/HorizontalScroll';
import { useHorizontalScrollDimensions } from 'components/HorizontalScroll/useHorizontalScrollDimensions';
import usePrintingMode from 'components/FlexTable/usePrintingMode';

import { SkeletonCell } from './shared/SkeletonCell';

import { DefaultColumnDragHandle } from './DefaultColumnDragHandle';
import { DefaultColumnSortingControl } from './DefaultColumnSortingControl';

declare module '@tanstack/table-core' {
  interface TableMeta<TData extends RowData> {
    /** There is data available, but we are fetching new (pagination etc.) */
    displayingPreviousData?: boolean;
    /** Fetching data for the first time, there is no previous data available. */
    displaySkeleton?: boolean;
  }

  interface ColumnMeta<TData extends RowData, TValue> {
    /** Pass classes to attach to this column's GenericTH cells */
    headerClasses?: string[];
    /** Pass classes to attach to this column's GenericTD cells */
    cellClasses?: string[];
  }
}

declare module '@tanstack/table-core/build/lib/types' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnDefExtensions<TData extends RowData, TValue = unknown> {
    /** Use this to specify available sorting options for a column */
    sortingDirections?: SortDirection[];
  }
}

export type GenericTableComponentProps = PropsWithChildren<
  {
    table: Table<unknown>;
  } & TableHTMLAttributes<HTMLTableElement>
>;

export type GenericTableComponent = ComponentType<GenericTableComponentProps>;

export function generateRandomIdNotIn(ids: string[]): string {
  let id;
  do {
    id = Math.random().toString(36).slice(2);
  } while (ids.includes(id));
  return id;
}

export function GenericTableElement({ table, children, style, ...props }: GenericTableComponentProps) {
  let width: number | undefined = table.getTotalSize();
  let minWidth: string | undefined = undefined;

  if (table.options.meta?.width === 'auto') {
    width = undefined;
  }

  if (table.options.meta?.width === 'fill') {
    minWidth = '100%';
  }

  if (table.options.meta?.width === 'full') {
    minWidth = '100%';
  }

  return (
    <table
      style={{
        position: 'relative',
        width,
        minWidth,
        ...style,
      }}
      {...props}
    >
      {children}
    </table>
  );
}

export type GenericTRProps = PropsWithChildren<
  {
    table: Table<unknown>;
    row?: Row<unknown>;
    ref?: Ref<HTMLTableRowElement>;
    'data-index'?: number;
    'data-depth'?: number;
  } & HTMLAttributes<HTMLTableRowElement>
>;

export type GenericTRComponent = ComponentType<GenericTRProps>;

export const GenericTR = forwardRef<HTMLTableRowElement, GenericTRProps>(
  ({ table, row, children, style, ...props }, ref) => {
    return (
      <tr ref={ref} style={{ width: 'fit-content', ...style }} {...props}>
        {children}
      </tr>
    );
  }
);

export type GenericTHProps = PropsWithChildren<
  {
    table: Table<unknown>;
    header: Header<unknown, unknown>;
  } & ThHTMLAttributes<HTMLTableCellElement>
>;

export type GenericTHComponent = ComponentType<GenericTHProps>;

export function GenericTH({ table, header, children, style, className, ...props }: GenericTHProps) {
  const classSet = new Set<string>([
    ...(className?.split(' ') ?? []),
    ...(header.column.columnDef.meta?.headerClasses ?? []),
  ]);

  return (
    <th
      style={{
        position: 'relative',
        width: table.options.meta?.width === 'auto' ? undefined : header.getSize(),
        minWidth: table.options.meta?.width === 'full' ? `${header.column.columnDef?.minSize ?? 0}px` : undefined,
        ...style,
      }}
      className={[...classSet].join(' ')}
      {...props}
    >
      {children}
    </th>
  );
}

export type GenericTDProps = PropsWithChildren<
  {
    table: Table<unknown>;
    cell: Cell<unknown, unknown>;
  } & TdHTMLAttributes<HTMLTableCellElement>
>;

export type GenericTDComponent = ComponentType<GenericTDProps>;

export function GenericTD({ table, cell, children, style, className, ...props }: GenericTDProps) {
  const classSet = new Set<string>([
    ...(className?.split(' ') ?? []),
    ...(cell.column.columnDef.meta?.cellClasses ?? []),
  ]);

  return (
    <td
      style={{
        width: table.options.meta?.width === 'auto' ? undefined : cell.column.getSize(),
        minWidth: table.options.meta?.width === 'full' ? `${cell.column.columnDef?.minSize ?? 0}px` : undefined,
        ...style,
      }}
      className={[...classSet].join(' ')}
      {...props}
    >
      {children}
    </td>
  );
}

export type ColumnDragHandleProps<TData extends RowData> = {
  header: Header<TData, unknown>;
  table: Table<TData>;
};

export type GenericColumnHandleComponent<TData extends RowData> = ComponentType<
  ColumnDragHandleProps<TData> & HTMLAttributes<HTMLDivElement>
>;

export type DefaultColumnSortingControlProps<TData extends RowData> = {
  header: Header<TData, unknown>;
};

export type GenericColumnSortingControlComponent<TData extends RowData> = ComponentType<
  DefaultColumnSortingControlProps<TData> & HTMLAttributes<HTMLDivElement>
>;

export const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

/**
 * Utility component to provide default styling to cells. It can be used as a wrapper for custom cell components.
 * There are for example, intentionally, no default paddings in cells, if a cell component is provided, so that it
 * can easily occupy any region of the cell.
 *
 * To change how the defaults are rendered, tweak class "textLabel" in the style provider.
 */
export function TableLabelWrapper({
  children,
  className,
  ...restProps
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  const classes = className?.split(/\s+/) ?? [];
  classes.push('textLabel');
  return (
    <div className={classes.join(' ')} {...restProps}>
      {children}
    </div>
  );
}

export type TableHeaderLabelProps<TData extends RowData> = {
  header: Header<TData, unknown>;
};

export function TableHeaderLabel<TData extends RowData>({ header }: TableHeaderLabelProps<TData>): JSX.Element | null {
  if (header.isPlaceholder) {
    return null;
  }

  const headerContents = flexRender(header.column.columnDef.header, header.getContext());

  if (typeof header.column.columnDef.header === 'string') {
    // Wrap label for default formatting
    return <TableLabelWrapper>{headerContents}</TableLabelWrapper>;
  }

  return <>{headerContents}</>;
}

export type TableHorizontalScrollerProps<TData extends RowData> = PropsWithChildren<
  {
    table: Table<TData>;
  } & HTMLAttributes<HTMLDivElement>
>;

export function TableHorizontalScroller<TData extends RowData>({
  table,
  children,
  ...restProps
}: TableHorizontalScrollerProps<TData>) {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const scrollingState = useHorizontalScrollDimensions(containerRef);

  let leftShadowStyle, rightShadowStyle;
  if (scrollingState) {
    const { horizontalScroll, contentWidth, clientHeight, clientWidth } = scrollingState ?? {};

    const [firstCenterColumn] = table.getCenterVisibleLeafColumns();
    const [firstRightColumn] = table.getRightVisibleLeafColumns();

    const leftShadowOffset = firstCenterColumn?.getStart() ?? 0;
    const rightShadowOffset = firstRightColumn ? table.getTotalSize() - firstRightColumn?.getStart() : 0;

    const displayLeftShadow = horizontalScroll > 0;
    const displayRightShadow = horizontalScroll < contentWidth - clientWidth;

    const height = clientHeight ?? 0;

    leftShadowStyle = { left: leftShadowOffset, height, opacity: displayLeftShadow ? 0.08 : 0, zIndex: 1 };
    rightShadowStyle = { right: rightShadowOffset, height, opacity: displayRightShadow ? 0.08 : 0, zIndex: 1 };
  }

  return (
    <div ref={containerRef} style={{ overflowX: 'auto', overflowY: 'hidden' }} {...restProps}>
      {children}
      <div style={{ position: 'absolute', pointerEvents: 'none', inset: 0 }}>
        <LeftShadow style={leftShadowStyle} />
        <RightShadow style={rightShadowStyle} />
      </div>
    </div>
  );
}

export type GenericTableProps<TData extends RowData> = {
  table: Table<TData>;
  Table?: GenericTableComponent;
  TR?: GenericTRComponent;
  TH?: GenericTHComponent;
  TD?: GenericTDComponent;
  ColumnDragHandle?: GenericColumnHandleComponent<TData>;
  ColumnSortingControl?: GenericColumnSortingControlComponent<TData>;
  scrollerProps?: Omit<TableHorizontalScrollerProps<TData>, 'table'>;
};

/**
 * Generic, unstyled table component.
 *
 * If header is a string it will be wrapped to a default `div.textLabel` that can be styled using
 * a style provider component, see {@link DefaultTableStyleProvider}. Similar thing happens to cells
 * but that comes from the {@link useTable} hook.
 *
 * To enable side-scrolling, confine the component that contains this table to a given width, e.g. '100%'.
 * E.g. {@link RichTable} does this for you.
 *
 * @param table As output by e.g. {@link useTable}.
 * @param Table Component to render `<table>`, defaults to {@link GenericTableElement}.
 * @param TR Component to render `<tr>`, defaults to {@link GenericTR}.
 * @param TH Component to render `<th>`, defaults to {@link GenericTH}.
 * @param TD Component to render `<td>`, defaults to {@link GenericTD}.
 * @param ColumnDragHandle Component to render column drag handle, if resize is enabled.
 * @param ColumnSortingControl Component to render sorting status, if sorting is enabled (asc/desc/none).
 * @param scrollerProps Any props to pass to the enclosing `<TableHorizontalScroller>`.
 */
export function GenericTable<TData extends RowData>({
  table,
  Table = GenericTableElement,
  TR = GenericTR,
  TH = GenericTH,
  TD = GenericTD,
  ColumnDragHandle = DefaultColumnDragHandle,
  ColumnSortingControl = DefaultColumnSortingControl,
  scrollerProps,
}: GenericTableProps<TData>): JSX.Element {
  const printing = usePrintingMode();

  const displayingPreviousData = Boolean(table.options.meta?.displayingPreviousData);
  const skeletonRowsToRender = table.options.meta?.displaySkeleton ? [...Array(3)] : [];

  const flatHeaderGroups = [
    ...table.getLeftHeaderGroups(),
    ...table.getCenterHeaderGroups(),
    ...table.getRightHeaderGroups(),
  ];

  // Make sure that pinned groups are split
  const topLevelHeaderGroups: HeaderGroup<TData>[] = table.getHeaderGroups().map(group => ({ ...group, headers: [] }));
  for (const headerGroup of flatHeaderGroups) {
    topLevelHeaderGroups[headerGroup.depth].headers.push(...headerGroup.headers);
  }

  return (
    <div>
      <TableHorizontalScroller table={table} {...scrollerProps}>
        <Table table={table as Table<unknown>} className={displayingPreviousData ? 'previousData' : ''}>
          <thead>
            {topLevelHeaderGroups.map(headerGroup => {
              const headers = headerGroup.headers.map(header => {
                // Make sure that only headers that come from left or right are marked as pinned.
                // Column grouping combined with pinning can cause groups to be split in the middle.
                // Note! The string matching relies on library implementation details and is subject
                // to change. If this happens, e.g. another index can be built to check where the
                // header comes from.
                const headerIsPinned =
                  header.column.getIsPinned() &&
                  (header.headerGroup.id.startsWith('left_') || header.headerGroup.id.startsWith('right_'));

                const headerClasses = [header.column.getIsSorted() && 'sorted', headerIsPinned && 'pinned']
                  .filter(Boolean)
                  .join(' ');

                const headerStyles: CSSProperties = {};
                if (headerIsPinned) {
                  headerStyles.position = 'sticky';
                  if (header.column.getIsPinned() === 'left') {
                    headerStyles.left = header.column.getStart('left');
                  } else if (header.column.getIsPinned() === 'right') {
                    headerStyles.right = header.column.getStart('right');
                  }
                }

                return (
                  <TH
                    key={header.id}
                    colSpan={header.colSpan}
                    table={table as Table<unknown>}
                    header={header as Header<unknown, unknown>}
                    data-pinned={header.column.getIsPinned() || undefined}
                    className={headerClasses}
                    style={headerStyles}
                  >
                    <TableHeaderContainer>
                      <TableHeaderLabel header={header} />
                      {table.options.enableSorting && header.column.getCanSort() && (
                        <ColumnSortingControl header={header as Header<TData, unknown>} />
                      )}
                    </TableHeaderContainer>

                    {table.options.enableColumnResizing && header.column.getCanResize() && (
                      <ColumnDragHandle table={table} header={header as Header<TData, unknown>} />
                    )}
                  </TH>
                );
              });

              if (table.options.meta?.width === 'fill') {
                const ids = headerGroup.headers.map(header => header.id);
                headers.push(<th key={generateRandomIdNotIn(ids)} />);
              }

              return (
                <TR key={headerGroup.id} table={table as Table<unknown>}>
                  {headers}
                </TR>
              );
            })}
          </thead>

          <tbody>
            {!printing &&
              skeletonRowsToRender.map((empty, index) => {
                const childColumns = table.getVisibleLeafColumns().map(column => (
                  <td key={column.id}>
                    <SkeletonCell />
                  </td>
                ));

                if (table.options.meta?.width === 'fill') {
                  const ids = table.getVisibleLeafColumns().map(column => column.id);
                  childColumns.push(<td key={generateRandomIdNotIn(ids)} />);
                }

                return <tr key={String(index)}>{childColumns}</tr>;
              })}

            {table.getRowModel().rows.map(row => {
              const visibleCells = [
                ...row.getLeftVisibleCells(),
                ...row.getCenterVisibleCells(),
                ...row.getRightVisibleCells(),
              ];

              const rowClasses = [row.getIsExpanded() && 'expanded', row.getIsSelected() && 'selected']
                .filter(Boolean)
                .join(' ');

              const cells = visibleCells.map(cell => {
                const cellClasses = [cell.column.getIsSorted() && 'sorted', cell.column.getIsPinned() && 'pinned']
                  .filter(Boolean)
                  .join(' ');

                const cellStyles: CSSProperties = {};

                if (cell.column.getIsPinned()) {
                  cellStyles.position = 'sticky';
                  if (cell.column.getIsPinned() === 'left') {
                    cellStyles.left = cell.column.getStart('left');
                  } else if (cell.column.getIsPinned() === 'right') {
                    cellStyles.right = cell.column.getStart('right');
                  }
                }

                return (
                  <TD
                    key={cell.id}
                    table={table as Table<unknown>}
                    cell={cell as Cell<unknown, unknown>}
                    data-pinned={cell.column.getIsPinned() || undefined}
                    data-depth={row.depth}
                    className={cellClasses}
                    style={cellStyles}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TD>
                );
              });

              if (table.options.meta?.width === 'fill') {
                const ids = visibleCells.map(cell => cell.id);
                cells.push(<td key={generateRandomIdNotIn(ids)} />);
              }

              return (
                <TR
                  key={row.id}
                  table={table as Table<unknown>}
                  row={row as Row<unknown>}
                  className={rowClasses}
                  data-depth={row.depth}
                  data-index={row.index}
                >
                  {cells}
                </TR>
              );
            })}
          </tbody>
        </Table>
      </TableHorizontalScroller>
    </div>
  );
}
