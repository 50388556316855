import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import queryString from 'query-string';
import memoizeOne from 'memoize-one';

const memoizedQuery = memoizeOne(search => queryString.parse(search));

const memoizedCreateSetQuery = memoizeOne(
  (useHash, history) =>
    (newQuery: object, options = { replace: false, pathname: undefined, useReplace: false }) => {
      let newSearch = {};
      // We use window.location.hash and window.location.search to prevent setQuery from changing when query changes
      const query = queryString.parse(useHash ? window.location.hash : window.location.search);
      if (!options.replace) {
        newSearch = { ...query };
      }
      newSearch = { ...newSearch, ...newQuery };

      const newState = {
        pathname: options.pathname,
        search: queryString.stringify({ ...newSearch }),
      };

      if (options.useReplace) {
        history.replace(newState);
      } else {
        history.push(newState);
      }
    }
);

export const useQuery = ({ useHash = false } = {}) => {
  const location = useLocation();
  const query = memoizedQuery(useHash ? location.hash : location.search);
  const history = useHistory();

  return useMemo(
    () => ({
      query,
      setQuery: memoizedCreateSetQuery(useHash, history),
    }),
    [history, query, useHash]
  );
};

export default useQuery;
