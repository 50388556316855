import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SelectV3 from 'components/Select/SelectV3';
import { languageOptions } from 'containers/Application/Admin/UserForm/Settings/Settings';
import translations from 'decorators/Translations/translations';

const Wrapper = styled.div`
  display: inline-block;
`;

const LanguageSelector = ({ t, selectedLanguage, setSelectedLanguage }) => {
  return (
    <Wrapper>
      <SelectV3
        options={languageOptions(t)}
        onChange={value => setSelectedLanguage(value)}
        value={selectedLanguage}
        placeholder="Language"
      />
    </Wrapper>
  );
};

LanguageSelector.propTypes = {
  t: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string,
  setSelectedLanguage: PropTypes.func.isRequired,
};

export default translations(LanguageSelector);
