import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import TechniciansComments from 'containers/Technician/TechniciansComments/TechniciansComments';
import ServiceOrderChecklist from 'containers/Application/ServiceOrder/ServiceOrderReply/ServiceOrderChecklist';

import useTechniciansReport from '../TechniciansView/useTechniciansReport';
import ChecklistReportForm from 'containers/Application/Checklists/reports/ChecklistReportForm';
import ChecklistReportView from 'containers/Application/Checklists/reports/ChecklistReportView';
import translations from 'decorators/Translations/translations';
import { getChecklistNumbers } from 'containers/Application/ServiceOrder/ServiceOrderReply/utils';
import * as Styled from 'containers/Application/ServiceOrder/ServiceOrderReply/ServiceOrderReply.styled';
import useOnScreen from 'containers/Application/ServiceOrder/ServiceOrderReply/useOnScreen';

const TechniciansReply = ({
  t,
  serviceOrder,
  submitComment,
  showNotification,
  loading,
  model,
  onModelChange,
  isReportModalOpen,
  setIsReportModalOpen,
  setIsVisible,
}) => {
  const [template, report, reportFiles, submitReport, downloadFile, errorMessage] = useTechniciansReport(
    Boolean(serviceOrder.checklistTemplateId),
    serviceOrder.functionalLocation?.functionalLocation,
    showNotification
  );

  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  useEffect(() => {
    setIsVisible?.(isVisible);
  }, [setIsVisible, isVisible]);

  const openFormModal = () => setIsReportModalOpen(true);
  const closeFormModal = () => setIsReportModalOpen(false);

  const { questions, answers } = getChecklistNumbers(template, report);
  const showChecklist = Boolean(template || errorMessage);
  const isCompleted = serviceOrder.status === 'Completed';

  const replyForm = (
    <TechniciansComments
      t={t}
      serviceOrder={serviceOrder}
      onSubmit={submitComment}
      loading={loading}
      isTechnician
      isCompletionDisabled={serviceOrder.checklistTemplateId && questions > answers}
      model={model}
      onModelChange={onModelChange}
      text={{ heading: t('Change Status') }}
    />
  );

  const checklistInfo = (
    <ServiceOrderChecklist
      report={report}
      template={template}
      openReport={openFormModal}
      isCompleted={serviceOrder?.status === 'Completed'}
      errorMessage={errorMessage}
    />
  );

  return (
    <Styled.ReplyContainer ref={ref}>
      {isReportModalOpen && !isCompleted && (
        <ChecklistReportForm
          checklistTemplate={template}
          checklistReport={report}
          checklistReportFiles={reportFiles}
          onClose={closeFormModal}
          onSubmit={submitReport}
          onFileDownload={downloadFile}
        />
      )}
      {isReportModalOpen && isCompleted && (
        <ChecklistReportView
          checklistTemplate={template}
          checklistReport={report}
          checklistReportFiles={reportFiles}
          onClose={closeFormModal}
          onFileDownload={downloadFile}
        />
      )}
      {showChecklist && <>{checklistInfo}</>}
      {replyForm}
    </Styled.ReplyContainer>
  );
};

TechniciansReply.propTypes = {
  t: PropTypes.func.isRequired,
  serviceOrder: PropTypes.object.isRequired,
  submitComment: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  showReplyButton: PropTypes.bool,
  model: PropTypes.object.isRequired,
  onModelChange: PropTypes.func.isRequired,
};

export default translations(TechniciansReply);
