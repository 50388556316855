import React from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';

import PrimaryButton from 'components/Button/PrimaryButton';
import ServiceOrderScore from './ServiceOrderScore';

import * as Styled from './ServiceOrderChecklist.styled';
import { getChecklistNumbers } from './utils';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';
import SkeletonText from 'components/Skeletons/SkeletonText';

const ServiceOrderChecklist = props => {
  const { t, openReport, template, report, isCompleted, errorMessage, disabled, loading } = props;

  const breakpoints = useBreakpoints();
  const { questions, answers } = getChecklistNumbers(template, report);
  const showScore = Boolean(isCompleted && template && report && !disabled);
  const showChecklistNumbers = !showScore && !errorMessage && !disabled;

  const landscapeNumbers = `${answers} / ${questions} ${t('questions answered')}`;
  const mobileNumbers = `(${landscapeNumbers})`;
  const checklistNumbers = breakpoints.landscape ? landscapeNumbers : mobileNumbers;

  if (loading) {
    <Styled.Container>
      <SkeletonText />
    </Styled.Container>;
  }

  if (!questions) {
    return <></>;
  }

  return (
    <Styled.Container>
      <Styled.TitleRow>
        <Styled.Title>{template?.title}</Styled.Title>
        <Styled.Info>
          {showScore && <ServiceOrderScore template={template} report={report} />}
          {showChecklistNumbers && checklistNumbers}
          {errorMessage && t(errorMessage)}
        </Styled.Info>
      </Styled.TitleRow>
      <Styled.ButtonContainer>
        <PrimaryButton add reveal onClick={openReport} disabled={!!errorMessage}>
          {t(disabled ? 'Preview checklist' : 'Open checklist')}
        </PrimaryButton>
      </Styled.ButtonContainer>
    </Styled.Container>
  );
};

ServiceOrderChecklist.propTypes = {
  t: PropTypes.func.isRequired,
  openReport: PropTypes.func.isRequired,
  template: PropTypes.object,
  report: PropTypes.object,
  isCompleted: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default translations(ServiceOrderChecklist);
