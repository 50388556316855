import React, { useRef, useState } from 'react';
import translations from 'decorators/Translations/translations';

import TechniciansViewInfo from '../TechniciansViewInfo/TechniciansViewInfo';
import TechniciansDocuments from '../TechniciansDocuments/TechniciansDocuments';
import ServiceOrderComments from 'containers/Application/ServiceOrder/ServiceOrderComments/ServiceOrderComments';
import ServiceOrderDescription from 'containers/Application/ServiceOrder/ServiceOrderDescription/ServiceOrderDescription';
import TechniciansReply from '../TechniciansComments/TechniciansReply';
import Content from 'components/Content/Content';
import * as Styled from 'components/Maintenance/CalendarPanels.styled';

import { OrderStatusColors } from 'constants/maintenance';
import { useReplyForm } from './useReplyForm';
import { useDocumentUpload } from './useDocumentUpload';
import ModalHeader from 'components/Maintenance/ModalHeader';
import useTabSelect from 'components/Maintenance/useTabSelect';
import { TabPanel } from 'components/Maintenance/Tabs.styled';
import TabBar from 'components/Maintenance/TabBar';

import {
  ServiceOrderContentsContainer,
  TabContents,
} from 'containers/Application/ServiceOrder/ServiceOrderView/ServiceOrderView.styled';
import { SidePanelContainer } from 'components/Maintenance/CalendarPanels.styled';
import styled from 'styled-components';
import ContactDetails from 'components/Maintenance/ContactDetails';
import useContactDetails from 'containers/Application/ServiceOrder/ServiceOrderView/useContactDetails';
import SupplierLink from './SupplierLink';
import ServiceOrderSnackbar from 'containers/Application/ServiceOrder/ServiceOrderView/ServiceOrderSnackbar';
import { serviceOrderHasChecklist } from 'containers/Application/ServiceOrder/ServiceOrderReply/utils';

const Container = styled(Content)`
  background-color: ${props => props.theme.colors.white};
  ${props => props.theme.media.landscape`
    margin: 0 auto ${props => props.theme.navigation.spacing};
    padding: var(--size-xl);
  `}
`;

export const TechniciansView = ({
  t,
  serviceOrder,
  documents,
  loading,
  loadingDocuments,
  onSubmitComment,
  onDocumentDownload,
  onDocumentUpload,
  showNotification,
}) => {
  const contacts = useContactDetails(serviceOrder, t);

  const sidePanelRef = useRef(null);
  const [replyInViewport, setReplyInViewport] = useState(true);
  const scrollReplyToView = () => sidePanelRef.current.scrollIntoView();
  const [checklistVisible, setChecklistVisible] = useState(false);
  const toggleShowChecklist = () => setChecklistVisible(!checklistVisible);
  const checklistAvailable = serviceOrderHasChecklist(serviceOrder);

  const tabs = [
    {
      id: 'service-order',
      title: t('Service Order'),
      Content: () => (
        <Styled.PanelContainer>
          <Styled.InfoContainer>
            <TechniciansViewInfo t={t} loading={loading} order={serviceOrder} />
          </Styled.InfoContainer>
          <Styled.DescriptionContainer>
            <ServiceOrderDescription t={t} order={serviceOrder} loading={loading} />
            <ContactDetails contacts={contacts} loading={loading} />
            <SupplierLink order={serviceOrder} t={t} />
            <ServiceOrderComments t={t} comments={serviceOrder.logs?.filter(log => log.comment)} loading={loading} />
          </Styled.DescriptionContainer>
        </Styled.PanelContainer>
      ),
    },
    {
      id: 'documents',
      title: loading ? t('Documents') : `${t('Documents')} (${documents.length})`,
      Content: () => (
        <TechniciansDocuments
          loadingDocuments={loadingDocuments}
          documents={documents}
          onDocumentDownload={onDocumentDownload}
          newFiles={newFiles}
          onFileUpload={handleFileUpload}
          onFileRemoval={handleFileRemoval}
          uploading={uploading}
          onUploadButtonClick={handleUpload}
          erroredFiles={erroredFiles}
        />
      ),
    },
  ];

  const { selectedTabIndex, setSelectedTabIndex, visibleContent } = useTabSelect(tabs);

  const { replyFormModel, handleReplyFormChange } = useReplyForm(serviceOrder);
  const { newFiles, erroredFiles, uploading, handleFileUpload, handleFileRemoval, handleUpload } =
    useDocumentUpload(onDocumentUpload);

  return (
    <Container>
      <ModalHeader
        order={serviceOrder}
        title={serviceOrder.title}
        statusColors={OrderStatusColors}
        functionalLocation={serviceOrder.functionalLocation}
        loading={loading}
      />
      <ServiceOrderContentsContainer>
        <TabPanel>
          <TabBar tabs={tabs} selectedTabIndex={selectedTabIndex} setSelectedTabIndex={setSelectedTabIndex} />
        </TabPanel>
        <TabContents>
          <visibleContent.Content />
        </TabContents>
        <SidePanelContainer ref={sidePanelRef}>
          <TechniciansReply
            setIsVisible={setReplyInViewport}
            serviceOrder={serviceOrder}
            submitComment={onSubmitComment}
            loading={loading}
            showNotification={showNotification}
            model={replyFormModel}
            onModelChange={handleReplyFormChange}
            isReportModalOpen={checklistVisible}
            setIsReportModalOpen={setChecklistVisible}
          />
        </SidePanelContainer>
        <ServiceOrderSnackbar
          replyInViewport={checklistVisible || replyInViewport}
          checklistAvailable={checklistAvailable}
          toggleShowChecklist={toggleShowChecklist}
          scrollReplyToView={scrollReplyToView}
          t={t}
        />
      </ServiceOrderContentsContainer>
    </Container>
  );
};

export default translations(TechniciansView);
