import React from 'react';
import styled, { css } from 'styled-components';

import { components, GroupBase, ValueContainerProps } from 'react-select-v5';

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const Label = styled.label<{ isFloating?: boolean }>`
  position: absolute;
  top: 0;
  width: 100%;
  pointer-events: none;

  line-height: ${props => props.theme.font.lineHeight.md};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.semibold};
  color: var(--input-placeholder-fg);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-right: ${props => props.theme.spacing.xs};
  transform: translateY(50%);
  transition: 0.2s ease all;
  ${props =>
    props.isFloating &&
    css`
      transform: translateY(0);
    `}
`;

export function ValueContainer<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  innerProps: ValueContainerProps<Option, IsMulti, Group>
) {
  const { label, placeholder } = innerProps.selectProps;

  return (
    <LabelWrapper>
      <Label className="filter-label" isFloating={innerProps.hasValue || !!placeholder}>
        {label}
      </Label>
      <components.ValueContainer {...innerProps}>{innerProps.children}</components.ValueContainer>
    </LabelWrapper>
  );
}
