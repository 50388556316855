import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { createSupplierFilterLink } from 'containers/Application/ServiceOrder/ServiceOrderInfo/utils';

const LinkWrapper = styled.div`
  font-size: 12px;
  line-height: ${props => props.theme.font.lineHeight.md};
  p {
    color: var(--grey-100);
  }
`;

const SupplierLink = ({ order, t }) => (
  <LinkWrapper>
    <Link
      to={createSupplierFilterLink(
        order,
        order.partnerNumberWithParents?.[order.partnerNumberWithParents.length - 1],
        order.functionalLocation?.functionalLocation
      )}
    >
      {t('See all service orders for this supplier')} →
    </Link>
    <p>({t('Requires SmartView credentials')})</p>
  </LinkWrapper>
);

export default SupplierLink;
