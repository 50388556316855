import ApiRequest from 'utils/Fetch/ApiRequest';
import { PROFILE_PATH } from './index';

export const apiQuota = (profileId) =>
  ApiRequest.get({
    apiPath: `${PROFILE_PATH}/apiQuotas/${profileId}`,
    noCache: true,
    useAuthorization: true,
  });

export const createApiQuota = (profileId, apiQuota) =>
  ApiRequest.put({
    apiPath: `${PROFILE_PATH}/apiQuotas/${profileId}`,
    data: apiQuota,
    noCache: true,
    useAuthorization: true,
  });

export const deleteApiQuota = (profileId) =>
  ApiRequest.delete({
    apiPath: `${PROFILE_PATH}/apiQuotas/${profileId}`,
    noCache: true,
    useAuthorization: true,
  });
