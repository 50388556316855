import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './ServiceOrderScore.styled';
import ChecklistScore from 'components/Calendar/ChecklistReports/ChecklistScore';
import { useTranslations } from 'decorators/Translations/translations';
import { getChecklistScore } from 'components/Calendar/ChecklistReports/utils';
import { ChecklistReportStatus } from 'constants/maintenance';

const ServiceOrderScore = ({ template, report }) => {
  const [t] = useTranslations();
  const { score, scoreDistribution } = getChecklistScore(template, report, ChecklistReportStatus.COMPLETED);

  return (
    <Styled.ScoreContainer>
      <span>{t('Score')}:</span>
      <ChecklistScore score={score} distribution={scoreDistribution} />
    </Styled.ScoreContainer>
  );
};

ServiceOrderScore.propTypes = {
  template: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
};

export default React.memo(ServiceOrderScore);
