import React from 'react';
import * as Styled from './ModalHeaderStatusBar.styled';
import { optionLabelOverrides } from 'containers/Application/Maintenance/utils';
import { TranslationFunction } from 'decorators/Translations/TranslationFunction';
import { OrderStatus, OrderStatusColors } from 'constants/maintenance';
import CalendarStatusTag from 'components/Calendar/CalendarStatusTag/CalendarStatusTag';
import { translatePriority } from 'utils/Data/serviceOrders';
import SkeletonText from 'components/Skeletons/SkeletonText';
import { Order, StatusColorMap } from './ModalHeader';
import { useTranslations } from 'decorators/Translations/translations';

type Props = {
  t: TranslationFunction;
  order?: Order;
  isOverdue?: boolean;
  loading: boolean;
  statusColors: StatusColorMap;
};

type StatusFieldProps = {
  title: string;
  value: React.ReactNode;
};

type SourceMap = {
  [key: string]: string;
};

const StatusField = ({ title, value }: StatusFieldProps) => {
  if (!value) {
    return <></>;
  }
  return (
    <Styled.Status>
      <Styled.StatusName>{title}</Styled.StatusName>
      {value}
    </Styled.Status>
  );
};

export const getStatusTag = (status: string, statusColors: StatusColorMap, text: string) => {
  if (!status || !text) {
    return null;
  }
  return (
    <CalendarStatusTag status={status} statusColors={statusColors || OrderStatusColors} text={text} showFullText />
  );
};

const StatusBar = (props: Props) => {
  const { order, isOverdue, loading, statusColors } = props;
  const [t] = useTranslations();
  if (loading) {
    return <SkeletonText />;
  }

  if (!order) {
    return null;
  }

  const { externalType, status, priority } = order;
  return (
    <Styled.StatusBar>
      <StatusField title={t('Status')} value={getStatusTag(status, statusColors, t(status))} />
      {externalType && (
        <StatusField
          title={t('Source')}
          value={(optionLabelOverrides.source as SourceMap)[externalType] || externalType}
        />
      )}
      <StatusField title={t('Priority')} value={translatePriority(priority, t)} />
      {isOverdue && getStatusTag(OrderStatus.OVERDUE, statusColors, t(OrderStatus.OVERDUE))}
    </Styled.StatusBar>
  );
};

export default StatusBar;
