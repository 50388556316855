import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { IOT_PATH } from './index';

export const sensorAlarmsForUserFn = request =>
  request.get({
    apiPath: `${IOT_PATH}/sensorAlarms/forUser`,
    noCache: true,
  });

export const upsertSensorAlarmFn = (request, sensorAlarm) =>
  request.patch({
    apiPath: `${IOT_PATH}/sensorAlarms`,
    data: sensorAlarm,
    noCache: true,
  });

export const deleteSensorAlarmFn = (request, sensorAlarmId) =>
  request.delete({
    apiPath: `${IOT_PATH}/sensorAlarms/${sensorAlarmId}`,
    noCache: true,
  });

export default wrapEachWithThunkRequest({
  sensorAlarmsForUser: sensorAlarmsForUserFn,
  upsertSensorAlarm: upsertSensorAlarmFn,
  deleteSensorAlarm: deleteSensorAlarmFn,
});
