import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useQuery from 'decorators/Query/useQuery';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import Footer from 'containers/Application/Footer/Footer';
import ErrorBoundary from 'components/ErrorPage/ErrorBoundary';

const FOOTER_HEIGHT = '168px';

// PublicView pages have isAnonymous prop
const ANONYMOUS_FOOTER_HEIGHT_MOBILE = '80px';
const ANONYMOUS_FOOTER_HEIGHT_DESKTOP = '105px';
const ANONYMOUS_NAVIGATION_HEIGHT = '64px';

const isAnonymousStyles = css`
  margin: 0 ${props => props.theme.layoutSpacing.md} ${props => props.theme.layoutSpacing.md} !important;
  padding-top: ${props =>
    props.noHeader ? 0 : `calc(${ANONYMOUS_NAVIGATION_HEIGHT} + ${props.theme.layoutSpacing.md})`} !important;
  min-height: ${props => (props.noHeight ? 'none' : `calc(100vh - ${ANONYMOUS_FOOTER_HEIGHT_MOBILE})`)} !important;

  ${props => props.theme.media.desktop`
    min-height: ${props => (props.noHeight ? 'none' : `calc(100vh - ${ANONYMOUS_FOOTER_HEIGHT_DESKTOP})`)} !important;
  `}
`;

const Article = styled.article`
  margin: 0 ${props => (props.noMargin ? 0 : props.theme.layoutSpacing.md)}
    ${props => (props.noMargin ? 0 : props.theme.layoutSpacing.md)};
  padding: ${props => (props.noHeader ? '0' : props.theme.navigation.height)} 0 0;
  transition: margin-left ${props => props.theme.navigation.transition};
  min-height: ${props => (props.noHeight ? 'none' : `calc(100vh - ${FOOTER_HEIGHT})`)};
  background-color: ${props => !props.noBackground && props.theme.colors.alabaster};
  display: flex;
  flex-direction: column;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  ${props => props.theme.media.portrait`
    min-height: ${props =>
      props.noHeight ? 'none' : `calc(100vh - ${FOOTER_HEIGHT} - ${props.noMargin ? '0px' : '48px'})`};
  `}

  ${props => props.theme.media.tablet`
    margin: 0 ${props => (props.noMargin ? 0 : props.theme.navigation.tabletSpacing)}
      ${props => (props.noMargin ? 0 : props.theme.layoutSpacing.md)};
  `}

  ${props => props.theme.media.landscape`
    padding: calc(${props => (props.noHeader ? '0px' : props.theme.navigation.height)} + ${props =>
    props.theme.navigation.tabletSpacing}) 0 0;
    margin-left: ${props =>
      !props.noMargin &&
      `calc(${props.naviExtended ? props.theme.navigation.sideTabletWidth : props.theme.navigation.sideSmallWidth} + ${
        props.theme.navigation.tabletSpacing
      })
      `};
  `}

  ${props => props.theme.media.desktop`
    min-height: ${props => (props.noHeight ? 'none' : `calc(100vh - 106px - ${props.noMargin ? '0px' : '48px'})`)};
    padding: calc(${props => (props.noHeader ? '0px' : props.theme.navigation.height)} + ${props =>
    props.theme.navigation.spacing}) 0 0;
    margin: 0 ${props => (props.noMargin ? 0 : props.theme.navigation.spacing)} ${props =>
    props.noMargin ? 0 : '3rem'};
    margin-left: ${props =>
      !props.noMargin &&
      `calc(${props.naviExtended ? props.theme.navigation.sideWidth : props.theme.navigation.sideSmallWidth} + ${
        props.theme.navigation.spacing
      })
    `};
  `};

  @media print {
    && {
      margin: 0;
      padding: 0;
      background-color: transparent;

      input,
      button {
        display: none;
      }
    }
  }

  ${props => props.isAnonymous && isAnonymousStyles}
`;

const renderScrollComponent = (disableScrollToTop, scrollToTopOnMount) => {
  if (!disableScrollToTop) {
    return <ScrollToTop mountOnly={scrollToTopOnMount} />;
  }
};

const StandardPage = ({
  noMargin = false,
  noHeight,
  noBackground,
  noFooter,
  isAnonymous = false,
  noHeader,
  withTabs = false,
  disableScrollToTop = false,
  scrollToTopOnMount = false,
  children,
}) => {
  const naviExtended = useSelector(state => state.navigation.sideNaviExtended);
  const { query } = useQuery();

  return (
    <Fragment>
      <Article
        noMargin={noMargin}
        noHeight={noHeight}
        withTabs={withTabs && query.noNavi !== 'true'}
        naviExtended={naviExtended}
        noBackground={noBackground}
        noHeader={noHeader}
        isAnonymous={isAnonymous}
      >
        {renderScrollComponent(disableScrollToTop, scrollToTopOnMount)}
        <ErrorBoundary>{children}</ErrorBoundary>
      </Article>
      {!noFooter && <Footer noMargin={noMargin} simpleMobile={isAnonymous} />}
    </Fragment>
  );
};

StandardPage.propTypes = {
  disableScrollToTop: PropTypes.bool,
  scrollToTopOnMount: PropTypes.bool,
  withTabs: PropTypes.bool,
  noMargin: PropTypes.bool,
  noHeight: PropTypes.bool,
  noBackground: PropTypes.bool,
  noFooter: PropTypes.bool,
  children: PropTypes.node,
  isAnonymous: PropTypes.bool,
  noHeader: PropTypes.bool,
};

export default StandardPage;
