import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import parseISO from 'date-fns/parseISO';
import { format } from 'utils/Date/dateFormatter';
import translations from 'decorators/Translations/translations';
import { Link } from 'react-router-dom';

import { createSupplierFilterLink } from 'containers/Application/ServiceOrder/ServiceOrderInfo/utils';
import InfoTable from 'components/Maintenance/InfoTable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const LinkWrapper = styled.p`
  font-size: ${props => props.theme.font.size.xs};
  line-height: ${props => props.theme.font.lineHeight.lg};
`;

const LinkInfo = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xs};
  line-height: ${props => props.theme.font.lineHeight.lg};
`;

const Header = styled.h4``;
Header.displayName = 'Header';

const getItems = (t, order) =>
  order
    ? [
        { label: t('ID'), value: order.serviceOrderNumber },
        { label: t('Functional location'), value: order.functionalLocation?.functionalLocation },
        { label: t('Category'), value: t(order.discipline) },
        { label: t('Created'), value: format(parseISO(order.createdDate), 'PPP, HH:mm') },
        { label: t('Last Update'), value: format(parseISO(order.modifiedDate), 'PPP, HH:mm') },
      ].filter(item => !!item.value)
    : [];

export const TechniciansViewInfo = ({ t, order, loading }) => {
  return <InfoTable header={t('Service Order Info')} loading={loading} items={getItems(t, order)} />;
};

TechniciansViewInfo.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
};

export default React.memo(translations(TechniciansViewInfo));
