import cookies from 'utils/Cookies/Cookies';
import { removeSession, SessionIdProvider } from './sessionProviders';
import { getWelcomeUrl } from 'utils/Data/language';
import { deleteSession, patchSession, preCheckProfile } from './session';
import { reportErrorToNewRelic } from 'utils/newRelic';

export const authenticateSession = async (welcomeUrl, authorizationUrl, code, state, idToken, authenticationType) => {
  const cnid = SessionIdProvider.get();

  if (state !== cnid) {
    throw new Error('Authorization state does not match with cnid.');
  }

  // Patch the session with authorization code and redirect URLs.
  const session = await patchSession(code, authorizationUrl, welcomeUrl, authenticationType);
  await preCheckProfile(idToken);
  const redirectUrl = session.redirectUrl || window.location.origin;
  return redirectUrl;
};

export const invalidateSession = async () => {
  const redirectUrl = getWelcomeUrl();
  try {
    const cnid = SessionIdProvider.get();
    if (!cnid) {
      setTimeout(function () {
        window.location = redirectUrl || '/';
      }, 0);
      return;
    }

    const session = await deleteSession();
    removeSession();

    // Redirect to Azure for AD logout
    setTimeout(function () {
      window.location = session.logoutUrl;
    }, 0);
  } catch (error) {
    console.error('Caught error trying to remove session', error);
    reportErrorToNewRelic(error);

    removeSession();
    setTimeout(function () {
      window.location = redirectUrl || '/';
    });
  }
};

export const handleUnauthorizedRequest = () => {
  const { location } = window;
  const pathsNotToRedirectTo = ['/Authorize', '/Logout'];
  if (!pathsNotToRedirectTo.some(path => location.pathname.startsWith(path))) {
    cookies.setItem('redirectTo', `${location.pathname}${location.search}`, Infinity, '/');
  }
};
