import ApiRequest from 'utils/Fetch/ApiRequest';
import { setSession, removeSession, SessionIdProvider } from './sessionProviders';

const SERVICE_API_PATH = '/api';
const PROFILE_PATH = '/profile';

const handleSessionUpsertResponse = session => {
  setSession(session);
  return session;
};

const handleSessionDeleteResponse = session => {
  removeSession(session);
  return session;
};

export const preCheckProfile = async token => {
  try {
    await ApiRequest.post({
      apiPath: `${SERVICE_API_PATH}/me`,
      noCache: true,
      useAuthorization: true,
      data: { token },
    });
  } catch (error) {
    console.error(error);
  }
};

export const createSession = (authorizationUrl, data) =>
  ApiRequest.post({
    apiPath: `${PROFILE_PATH}/sessions`,
    useAuthorization: false,
    data: { authorizationUrl, data },
  }).then(handleSessionUpsertResponse);

export const createSessionByCredentials = (username, password) =>
  ApiRequest.post({
    apiPath: `${PROFILE_PATH}/sessions/byCredentials`,
    useAuthorization: false,
    data: { username, password },
  }).then(handleSessionUpsertResponse);

export const refreshSession = data =>
  ApiRequest.put({
    apiPath: `${PROFILE_PATH}/sessions/${SessionIdProvider.get()}`,
    useAuthorization: false,
    data: { data },
  }).then(handleSessionUpsertResponse);

export const patchSession = (code, authorizationUrl, welcomeUrl, authenticationType) =>
  ApiRequest.patch({
    apiPath: `${PROFILE_PATH}/sessions/${SessionIdProvider.get()}`,
    useAuthorization: false,
    data: { code, authorizationUrl, welcomeUrl, authenticationType },
  }).then(handleSessionUpsertResponse);

export const deleteSession = () =>
  ApiRequest.delete({
    apiPath: `${PROFILE_PATH}/sessions/${SessionIdProvider.get()}`,
    useAuthorization: true,
    enableSessionInvalidation: false,
  }).then(handleSessionDeleteResponse);
