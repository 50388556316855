import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import ApiRequest from 'utils/Fetch/ApiRequest';
import Application from 'containers/Application/Application';
import AcceptAuthorization from 'containers/Application/Authorization/AcceptAuthorization';
import Login from 'containers/Application/Authorization/Login';
import WelcomeNewUser from 'containers/Application/User/WelcomeNewUser';
import Helpdesk from 'containers/Application/Helpdesk/Helpdesk';
import SomethingWentWrong from 'containers/Application/User/SomethingWentWrong';
import LoginHelp from 'containers/Application/User/LoginHelp';
import GeneralLoading from 'containers/GeneralLoading/GeneralLoading';
import Loading from 'components/Loading/Loading';
import TermsOfServicePage from 'containers/Application/ToS/TermsOfServicePage';
import UserTermsPage from 'containers/Application/ToS/UserTermsPage';
import Technician from 'containers/Technician/Technician';
import LinkRedirect from 'containers/LinkRedirect/LinkRedirect';
import PublicView from 'containers/PublicView/PublicView';
import PublicRescuePlan from 'containers/PublicRescuePlan/PublicRescuePlan';
import AdditionalSale from 'containers/AdditionalSale/AdditionalSalePage';

import themes from 'styles/themes';
import ResetCss from 'styles/globalStyles/globalStylesReset';
import BaseCss from 'styles/globalStyles/globalStylesApplication';

import configureStore from './redux/create';
import LocalizationProvider from './contexts/LocalizationProvider';
import NoProfileFound from './containers/Application/User/NoProfileFound';
import IENotification from 'components/IENotification/IENotification';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loadAuthConfig } from 'utils/Fetch/authConfig';
import queryString from 'query-string';

import 'styles/UIv3/UIv3Theme.scss';

const history = createBrowserHistory();
const store = configureStore(history);

const files = require.context('../app/images/svg/', false, /.*\.inline\.svg$/);
files.keys().forEach(files);

const queryClient = new QueryClient();

// Check if we landed to sso and store information so sessionStorage. This way we can load correct msal config.
if (history.location.pathname === '/sso' && queryString.parse(history.location.search).domain) {
  sessionStorage.setItem('sso', true);
} else if (history.location.pathname === '/login') {
  sessionStorage.removeItem('sso');
  sessionStorage.removeItem('loginHint');
}

ApiRequest.initialize().then(msalInstance => {
  ReactDOM.render(
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Fragment>
                <ResetCss />
                <BaseCss />
                <Switch>
                  <Route exact path="/Authorize" component={AcceptAuthorization} />
                  <Route exact path="/AuthorizeB2C" component={AcceptAuthorization} />
                  <Route path="/Welcome" component={WelcomeNewUser} store={store} />
                  <Route path="/fi/Helpdesk" component={Helpdesk} />
                  <Route path="/SomethingWentWrong" component={SomethingWentWrong} />
                  <Route path="/NoProfileFound" component={NoProfileFound} />
                  <Route path="/LoginHelp" component={LoginHelp} />
                  <Route path="/Technician" component={Technician} store={store} />
                  <Route path="/TermsOfService" component={TermsOfServicePage} store={store} />
                  <Route path="/UserTerms" component={UserTermsPage} store={store} />
                  <Route path="/lnk/:id" component={LinkRedirect} store={store} />
                  <Route path="/view/:id" component={PublicView} store={store} />
                  <Route path="/pelsu/:id" component={PublicRescuePlan} store={store} />
                  <Route path="/WorkProposal" component={AdditionalSale} store={store} />
                  <Route exact path="/sso" component={Login} />
                  <Route exact path="/login" component={Login} />
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={{
                      scopes: loadAuthConfig().auth.scopes,
                      loginHint: sessionStorage.getItem('loginHint'),
                    }}
                    errorComponent={SomethingWentWrong}
                    loadingComponent={Loading}
                  >
                    <Application />
                  </MsalAuthenticationTemplate>
                </Switch>
                <ThemeProvider theme={themes.customerPlatform}>
                  <GeneralLoading />
                  <IENotification />
                </ThemeProvider>
              </Fragment>
            </ConnectedRouter>
          </Provider>
        </LocalizationProvider>
      </QueryClientProvider>
    </MsalProvider>,
    document.getElementById('root')
  );
});
