import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: ${props => props.hideOnMobile && 'none'};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.theme.navigation.height};
  z-index: ${props => props.theme.zIndex('header')};

  ${props => props.theme.media.landscape`
    display: block;
    position: fixed;
  `};
`;

const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.theme.navigation.height};
  width: 100%;
  padding: 0 var(--size-md);
  background-color: var(--brand-color);
  z-index: ${props => props.theme.zIndex('header')};
`;

const RightSide = styled.div`
  display: inline-flex;
`;

const Logo = styled.img`
  height: var(--logo-header-height, 50px);
`;

const Link = styled.a`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSize.xs};
`;

const AnonymousHeader = ({ children, showCaverionLink, hideOnMobile }) => (
  <HeaderContainer hideOnMobile={hideOnMobile}>
    <Header>
      <Logo src="/Caverion-SmartView-White-RGB.svg" alt="logo" />
      <RightSide>
        {children}
        {showCaverionLink && <Link href="http://www.caverion.com">Caverion.com main site &rarr;</Link>}
      </RightSide>
    </Header>
  </HeaderContainer>
);

AnonymousHeader.propTypes = {
  children: PropTypes.node,
  showCaverionLink: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
};

export default AnonymousHeader;
