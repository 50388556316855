import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import isNil from 'lodash/isNil';
import { setSplashLoading } from 'redux/modules/index';
import { getWelcomeUrl } from 'utils/Data/language';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import themes from 'styles/themes';
import { authenticateSession } from 'services/profile/sessionUtils';
import useActions from 'redux/utils/useActions';
import useQuery from 'decorators/Query/useQuery';
import { useLocation } from 'react-router-dom';

const AcceptAuthorization = () => {
  const [redirectUrl, setRedirectUrl] = useState();
  const [error, setError] = useState();
  const [setLoading] = useActions([setSplashLoading]);
  const { query } = useQuery({ useHash: true });
  const { pathname } = useLocation();

  useEffect(() => {
    setLoading(true);
    if (!isNil(query.code) && !isNil(query.state)) {
      authenticateSession(
        getWelcomeUrl(),
        `${window.location.origin}${pathname}`,
        query.code,
        query.state,
        query.id_token,
        pathname === '/AuthorizeB2C' ? 'b2c' : 'aad'
      )
        .then((redirectUrl = '/') => {
          setRedirectUrl(redirectUrl);
        })
        .catch(error => {
          console.log('Caught error in authentication', error);
          setError(error);
          setLoading(false);
        });
    }
  }, []);

  if (error) {
    return (
      <ThemeProvider theme={themes.customerPlatform}>
        <ErrorPage type="authentication" />
      </ThemeProvider>
    );
  }
  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }
  return null;
};

export default AcceptAuthorization;
