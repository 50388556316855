import ApiRequest from 'utils/Fetch/ApiRequest';
import { PROFILE_PATH } from './index';

export const apiKeys = (profileId) =>
  ApiRequest.get({
    apiPath: `${PROFILE_PATH}/apiKeys/`,
    query: { profileId },
    useAuthorization: true,
  });

export const createApiKey = (apiKey) =>
  ApiRequest.post({
    apiPath: `${PROFILE_PATH}/apiKeys`,
    data: apiKey,
    noCache: true,
    useAuthorization: true,
  });

export const deleteApiKey = (apiKeyId) =>
  ApiRequest.delete({
    apiPath: `${PROFILE_PATH}/apiKeys/${apiKeyId}`,
    noCache: true,
    useAuthorization: true,
  });
