import styled from 'styled-components';

export const Info = styled.span`
  display: block;
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
`;

export const SubSection = styled.div`
  width: 100%;
  min-width: fit-content;

  & > * + * {
    margin-top: 0.75em;
  }
  & > *:last-child {
    margin-bottom: 0.75em;
  }

  h3 {
    font-size: ${props => props.theme.font.size.xs};
    font-weight: ${props => props.theme.font.weight.bold};
  }

  p {
    font-size: ${props => props.theme.font.size.xs};
    line-height: ${props => props.theme.font.lineHeight.lg};
    color: ${props => props.theme.colors.black};
    overflow-wrap: break-word;
  }
`;
