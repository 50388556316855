import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import { InputRow, InputLabel, InputSelectDropdown, InputForm, InputBooleanCheckbox } from 'components/index';
import FileUpload from 'components/FileUpload/FileUpload';

const StyledInputRow = styled(InputRow)`
  width: 100%;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: ${props => props.noBottomMargin && '0'};
`;

const NewFolder = styled.p`
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size.xxs};
  text-transform: uppercase;
  margin-bottom: var(--size-md);
`;

const NewFolderLink = styled.a``;

const DocumentForm = ({
  t,
  formId,
  model,
  onChange,
  onSubmit,
  onFileUpload,
  onNewFolderClick,
  children,
  folderOptions,
  isDocumentsAdmin,
  isCreateMode,
}) => (
  <InputForm id={formId} model={model} onPropertyChange={onChange} onSubmit={onSubmit}>
    <StyledInputRow noBottomMargin={!isCreateMode}>
      <InputLabel text={isCreateMode ? t('Files') : t('File')} />
      {isCreateMode && (
        <FileUpload
          upload={onFileUpload}
          t={t}
          header={t('Click and select file or drag and drop')}
          noIcon
          noMimeTypes
          height="100px"
          multiple
          infoText={t(
            'Supported file types are the most common image, video, audio, text, presentation and Microsoft files.'
          )}
        />
      )}
    </StyledInputRow>

    {children}

    {!isEmpty(model) && (
      <>
        <StyledInputRow>
          <InputLabel text={t('Folder')} />
          <InputSelectDropdown property={[0, 'folderId']} t={t} options={folderOptions} scrollToMenu />
        </StyledInputRow>
        <NewFolder>
          <NewFolderLink onClick={onNewFolderClick}>+ {t('Create new folder')}</NewFolderLink>
        </NewFolder>
        {isDocumentsAdmin && (
          <StyledInputRow noBottomMargin>
            <InputBooleanCheckbox
              id={[0, 'adminOnly']}
              property={[0, 'adminOnly']}
              label={t('Set files private for Admins')}
            />
          </StyledInputRow>
        )}
      </>
    )}
  </InputForm>
);

DocumentForm.propTypes = {
  t: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  model: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onNewFolderClick: PropTypes.func.isRequired,
  folderOptions: PropTypes.array.isRequired,
  isDocumentsAdmin: PropTypes.bool.isRequired,
  isCreateMode: PropTypes.bool.isRequired,
  children: PropTypes.node,
};
export default DocumentForm;
