import cookies from 'utils/Cookies/Cookies';
import { reportErrorToNewRelic } from 'utils/newRelic';

export const SessionProvider = {
  getSession: () => {
    try {
      const rawSession = sessionStorage.getItem('session');
      if (rawSession?.length) {
        return JSON.parse(atob(rawSession));
      }
    } catch (error) {
      console.error('Could not get session', error);
      reportErrorToNewRelic(error);
    }
  },
  storeSession: session => {
    sessionStorage.setItem('session', btoa(JSON.stringify(session)));
  },
  removeSession: () => {
    sessionStorage.removeItem('session');
  },
};

export const SessionIdProvider = {
  get: () => cookies.getItem('cnid'),
  set: id => cookies.setItem('cnid', id, Infinity, '/'),
  remove: () => cookies.removeItem('cnid'),
};

export const setSession = session => {
  SessionIdProvider.set(session.id);
  SessionProvider.storeSession(session);
};

export const removeSession = () => {
  SessionIdProvider.remove();
  SessionProvider.removeSession();
};
