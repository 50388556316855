import { BrowserAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import Loading from 'components/Loading/Loading';
import queryString from 'query-string';
import { default as React, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { loadAuthConfig } from 'utils/Fetch/authConfig';

function Login() {
  const { instance, accounts, inProgress } = useMsal();
  const { search } = useLocation();
  const history = useHistory();
  const [username, setUsername] = useState(queryString.parse(search).username);
  const [domain, setDomain] = useState(queryString.parse(search).domain);

  if (instance.getActiveAccount()) {
    history.replace('/');
  }

  useEffect(() => {
    async function login() {
      /* eslint-disable-next-line no-undef */
      const cookies = await cookieStore.getAll();
      if (!username) {
        setUsername(cookies.find(cookie => cookie.name === 'username')?.value);
      }
      if (!domain) {
        setDomain(cookies.find(cookie => cookie.name === 'domain')?.value);
      }
      if (domain) {
        sessionStorage.setItem('loginHint', domain);
      }
      const config = {
        scopes: loadAuthConfig().auth.scopes,
        extraQueryParameters: {
          ...(domain ? { domain_hint: domain } : {}),
        },
        loginHint: username ?? domain,
        redirectStartPage: '/',
      };
      instance.loginRedirect(config).catch(error => {
        if (error instanceof BrowserAuthError && error.errorCode === 'interaction_in_progress') {
          sessionStorage.removeItem('msal.interaction.status');
          sessionStorage.removeItem(`msal.${loadAuthConfig().auth.clientId}.urlHash`);
          console.warn('Clearing MSAL cache and trying again');
          return instance.loginRedirect(config);
        }
      });
    }
    if (inProgress === 'none' && !instance.getActiveAccount()) {
      login();
    }
  }, [inProgress, accounts]);

  return <Loading showLogo={false} />;
}

export default Login;
