import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import withQuery from 'decorators/Query/withQuery';
import PropTypes from 'prop-types';

import { getSectionTitleText, scrollTo, getSectionOptions, SLICE_TYPE, CUSTOM_TYPE } from '../utils/UserManualUtils';
import SkeletonText from 'components/Skeletons/SkeletonText';
import UserManualSearch from './UserManualSearch';
import Select from 'components/Select/SelectV3';
import useQuery from 'decorators/Query/useQuery';
import useScrollPosition from 'decorators/Scroll/useScrollPosition';

export const MOBILE_SECTION_SELECTOR_HEIGHT = 70;

const StyledUserManualSectionListDesktop = styled.div`
  display: none;
  background-color: ${props => props.theme.colors.midnight};
  ${props => props.theme.media.landscape`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    padding-top: 48px;
    bottom: 0;
    width: 25%;
    max-width: 300px;
    padding-bottom: var(--size-md);
    transition: padding 0.1s ease-in;
  `}
  h3 {
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.colors.lightGray};
    line-height: 1.5;
  }
`;

StyledUserManualSectionListDesktop.displayName = 'StyledUserManualSectionListDesktop';

const StyledUserManualSectionListMobile = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding-top: ${props => (props.top ? 0 : props.theme.navigation.height)};
  transition: padding 0.1s ease-in;
  background-color: ${props => props.theme.colors.midnight};

  ${props => props.theme.media.landscape`
    display: none;
  `}
`;

StyledUserManualSectionListMobile.displayName = 'StyledUserManualSectionListMobile';

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.colors.rockBlue};
`;

Separator.displayName = 'Separator';

const UserManualSectionHeader = styled.p`
  width: 100%;
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.default};
  text-transform: uppercase;
  text-align: center;
  padding: var(--size-md);
  ${props => props.theme.media.landscape`
    padding: var(--size-xl);
    text-align: left;
  `}
  color: ${props => props.theme.colors.white};
`;

UserManualSectionHeader.displayName = 'UserManualSectionHeader';

const UserManualSectionTitles = styled.a`
  display: flex;
  padding: var(--size-xxs) var(--size-xl);
  h3 {
    margin-left: var(--size-md);
  }
  h2,
  h3 {
    color: ${props => props.theme.colors.rockBlue};
    font-size: ${props => props.theme.font.size.sm};
    &:hover {
      cursor: pointer;
    }
  }
`;

UserManualSectionTitles.displayName = 'UserManualSectionTitles';

const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  height: ${MOBILE_SECTION_SELECTOR_HEIGHT}px;
`;

const SelectBackground = styled.div``;

const UserManualSectionContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-top: var(--size-xl);

  .page-title h2 {
    color: white !important;
  }
  .page-title:not(:first-child) {
    margin-top: var(--size-xs);
  }
`;
UserManualSectionContainer.displayName = 'UserManualSectionContainer';

const getQueryString = query => `${query.page || ''}/${query.title || ''}`;

const getSkeleton = () => (
  <React.Fragment>
    <SkeletonText style={{ width: '80%', marginBottom: '1em' }} />
    <SkeletonText style={{ width: '80%', marginBottom: '1em' }} />
    <SkeletonText style={{ width: '80%', marginBottom: '1em' }} />
    <SkeletonText style={{ width: '80%', marginBottom: '1em' }} />
  </React.Fragment>
);

export const UserManualSectionList = ({ profile, pages, loading, onPageItemClick, onTitleItemClick }) => {
  const { query } = useQuery();
  const [selectedSection, setSelectedSection] = useState(getQueryString(query));

  const [scrollTop, scrollingUp] = useScrollPosition();

  const top = scrollingUp ? scrollTop > 64 + 20 : scrollTop > 20;

  useEffect(() => {
    scrollTo(selectedSection?.split('/')[1]);
  }, [selectedSection]);

  const getSectionContentDesktop = pages =>
    pages.map(page => (
      <React.Fragment key={page.uid}>
        {page.type === CUSTOM_TYPE.USER_MANUAL_PAGE && (
          <UserManualSectionTitles className="page-title" onClick={() => onPageItemClick(page.uid)}>
            {RichText.render(page.data.title)}
          </UserManualSectionTitles>
        )}
        {page.data?.body?.map((singleSectionContent, index) => {
          switch (singleSectionContent.slice_type) {
            case SLICE_TYPE.SECTION_SUBJECT:
            case SLICE_TYPE.REPEATABLE_SECTION_SUBJECT: {
              return (
                <UserManualSectionTitles className="page-title" onClick={() => onPageItemClick(page.uid)} key={index}>
                  {RichText.render(singleSectionContent.primary.contentSubject)}
                </UserManualSectionTitles>
              );
            }
            case SLICE_TYPE.SECTION_WITH_CONTENT: {
              const title = `${page.uid}/${getSectionTitleText(singleSectionContent) || ''}`;
              return (
                <UserManualSectionTitles
                  onClick={() => {
                    onTitleItemClick(...title.split('/'));
                    setSelectedSection(title);
                  }}
                  key={index}
                >
                  {RichText.render(singleSectionContent.primary.contentTitle)}
                </UserManualSectionTitles>
              );
            }
            default:
              return null;
          }
        })}
      </React.Fragment>
    ));

  const handleTitleClick = value => {
    if (value) {
      onTitleItemClick(...value.split('/'));
    }
    setSelectedSection(value);
  };

  return (
    <Fragment>
      <StyledUserManualSectionListDesktop>
        <UserManualSearch profile={profile} onResultClick={onPageItemClick} />
        <Separator />
        <UserManualSectionContainer>
          {loading && getSkeleton()}
          {!loading && pages && getSectionContentDesktop(pages)}
        </UserManualSectionContainer>
      </StyledUserManualSectionListDesktop>
      <StyledUserManualSectionListMobile top={top}>
        <SelectContainer>
          <SelectBackground>
            <Select
              value={selectedSection}
              onChange={handleTitleClick}
              options={getSectionOptions(pages)}
              disabled={loading}
              lightBackground={true}
              pxWidth={300}
              showBackground
            />
          </SelectBackground>
        </SelectContainer>
      </StyledUserManualSectionListMobile>
    </Fragment>
  );
};

UserManualSectionList.propTypes = {
  profile: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object.isRequired),
  scroll: PropTypes.shape({
    scrollTop: PropTypes.number.isRequired,
    scrollingUp: PropTypes.bool.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  onPageItemClick: PropTypes.func.isRequired,
  onTitleItemClick: PropTypes.func.isRequired,
};

export default withQuery(UserManualSectionList);
